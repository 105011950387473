import React from "react";
import { RootStore } from "stores";
import { EmployeeSnapshot } from "stores/EmployeeStore/EmployeeModel";
import DetailPage from "pages/Base/DetailPage";
import { observer } from "mobx-react-lite";
import { EmployeeForm } from "components/forms";

const EmployeeDetailPage = ({ match }: { match: { params: any } }) => {
  const onEditSubmit = async (
    employeeData: Partial<EmployeeSnapshot>,
    files: any
  ) => {
    await RootStore.EmployeeStore.current?.update(employeeData, files);
  };

  return (
    <DetailPage
      current={RootStore.EmployeeStore.current}
      store={RootStore.EmployeeStore}
      title={RootStore.EmployeeStore.current?.name}
      form={
        <EmployeeForm
          onSubmitCallback={onEditSubmit}
          employee={RootStore.EmployeeStore.current}
        ></EmployeeForm>
      }
      formColSize={12}
      match={match}
    ></DetailPage>
  );
};

export default observer(EmployeeDetailPage);
