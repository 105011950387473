import {
  types,
  Instance,
  SnapshotIn,
} from "mobx-state-tree";

const HelpQuestionAnswerModel = types.model("HelpQuestionAnswerModel", {
  id: types.identifierNumber,
  helpQuestionTitle: types.string,
  helpQuestionId: types.number,
  ticketId: types.maybeNull(types.number),
  userId: types.number,
  userName: types.maybeNull(types.string),
  userEmail: types.maybeNull(types.string),
  answer: types.maybe(types.string),
  audio: types.maybeNull(types.string),
  performanceId: types.maybeNull(types.number),
  createdAt: types.string,
  unanswered: types.boolean
});

export interface HelpQuestionAnswerSnapshot
  extends SnapshotIn<typeof HelpQuestionAnswerModel> {}
export interface HelpQuestionAnswerInstance
  extends Instance<typeof HelpQuestionAnswerModel> {}

export default HelpQuestionAnswerModel;
