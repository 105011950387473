import React, { useState } from "react";
import { RootStore } from "stores";
import { observer } from "mobx-react-lite";
import { RoomSnapshot } from "stores/RoomStore/RoomModel";
import { RoomForm } from "components/forms";
import { OverviewPage } from "pages/Base";

const RoomsOverviewPage = () => {
  const columns = [
    {
      dataField: "name",
      text: "Naam",
    },
  ];

  const [showModal, setShowModal] = useState<boolean>();

  const onCreateSubmit = async (room: Partial<RoomSnapshot>, files: any) => {
    await RootStore.RoomStore?.create(room, files);
    setShowModal(false);
  };

  const form = <RoomForm onSubmitCallback={onCreateSubmit}></RoomForm>;

  return (
    <>
      <OverviewPage
        name="Kamers"
        singular="Kamer"
        path="kamers"
        onLoad={() => RootStore.RoomStore.load()}
        columns={columns}
        items={RootStore.RoomStore.rooms}
        form={form}
        showModal={showModal}
      ></OverviewPage>
    </>
  );
};

export default observer(RoomsOverviewPage);
