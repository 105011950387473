import CrudService from "./CrudService";
import { TicketSnapshot } from "stores/UserStore/TicketModel";
import ApiService from "./ApiService";

class TicketService extends CrudService<TicketSnapshot> {
  createGroup = async (groupData: {
    amount: number;
    inviter: string;
    performanceId: number;
  }): Promise<string> => {
    const result = await ApiService.post(`${this.basename}/group`, groupData);
    return result.data;
  };

  cancelGroupTicket = async (id: number): Promise<boolean> => {
    const result = await ApiService.delete(`${this.basename}/${id}`);
    return result.data;
  };

  deleteGroupTicket = async (pincode: string): Promise<boolean> => {
    const result = await ApiService.delete(`${this.basename}/group/${pincode}`);
    return result.status === 204;
  };

  scan = async (id: number): Promise<boolean> => {
    const result = await ApiService.post(`${this.basename}/${id}/scan`);
    return result.data;
  };
}

export default new TicketService("/tickets");
