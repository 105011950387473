import moment from "moment-timezone";

class dateUtil {
  public getDateFromTimespan = (timespan: string): Date => {
    const duration = moment.duration(timespan);
    const date = moment();
    date.hours(duration.hours());
    date.minutes(duration.minutes());
    return date.toDate();
  };

  public UtcStringToFormattedString = (dateString: string): string => {
    const date = moment.utc(dateString).tz("Europe/Amsterdam");
    return date.format("DD-MM-yyyy HH:mm");
  };
}

export default new dateUtil();
