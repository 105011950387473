import React from "react";
import { RootStore } from "stores";
import { observer } from "mobx-react-lite";
import { StatementForm } from "components/forms";
import { StatementInstance } from "stores/StatementStore/StatementModel";
import DetailPage from "pages/Base/DetailPage";
import { StatementSnapshot } from "stores/StatementStore/StatementModel";

const StatementDetailPage = ({ match }: { match: { params } }) => {
  const onEditSubmit = async (statementData: Partial<StatementSnapshot>) => {
    await RootStore.StatementStore.current?.update(statementData);
  };

  return (
    <DetailPage<StatementInstance>
      current={RootStore.StatementStore.current}
      store={RootStore.StatementStore}
      title={RootStore.StatementStore.current?.name}
      form={
        <StatementForm
          onSubmitCallback={onEditSubmit}
          statement={RootStore.StatementStore.current}
        ></StatementForm>
      }
      match={match}
    />
  );
};

export default observer(StatementDetailPage);
