import React, { useState } from "react";
import { RootStore } from "stores";
import { FaqSnapshot } from "stores/FaqStore/FaqModel";
import DetailPage from "pages/Base/DetailPage";
import { observer } from "mobx-react-lite";
import { FaqForm } from "components/forms";
import { ConfirmModal } from "components";
import { useHistory } from "react-router";

const FaqDetailPage = ({ match }: { match: { params: any } }) => {
  const history = useHistory();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const onEditSubmit = async (faqData: Partial<FaqSnapshot>) => {
    await RootStore.FaqStore.current?.update(faqData);
  };

  const onRemoveSubmit = async () => {
    await RootStore.FaqStore.delete();
    history.push("/faq");
  };

  return (
    <>
      <DetailPage
        current={RootStore.FaqStore.current}
        store={RootStore.FaqStore}
        title={"FAQ vraag"}
        form={
          <FaqForm
            onSubmitCallback={onEditSubmit}
            onRemoveCallback={() => {
              setShowDeleteModal(true);
            }}
            faq={RootStore.FaqStore.current}
          ></FaqForm>
        }
        match={match}
      ></DetailPage>

      <ConfirmModal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        onConfirm={onRemoveSubmit}
        modalText="Weet je zeker dat je deze vraag wilt verwijderen?"
        modalTitle={"Vraag verwijderen"}
      ></ConfirmModal>
    </>
  );
};

export default observer(FaqDetailPage);
