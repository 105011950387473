import {
	types,
	Instance,
	flow,
	applySnapshot,
	SnapshotIn,
} from "mobx-state-tree";
import alertUtil from "util/alertUtil";
import QuestionModel from "./QuestionModel";
import StatementService from "services/StatementService";

export enum STATEMENT_TYPES {
	MULTIPLECHOICE = "Multiple choice",
	YESNO = "Ja/nee",
}

const StatementModel = types
	.model("StatementModel", {
		id: types.identifierNumber,
		text: types.string,
		type: types.enumeration(Object.keys(STATEMENT_TYPES)),
		yes: types.number,
		no: types.number,
		featured: types.boolean,
		active: types.boolean,
		questions: types.array(QuestionModel),
	})
	.actions((self) => ({
		update: flow(function* (
			statementData: Partial<SnapshotIn<typeof StatementModel>>
		) {
			const statement = yield StatementService.update(self.id, {
				id: self.id,
				...statementData,
			});

			statement.questions = self.questions;

			applySnapshot(self, statement);
			alertUtil.success("De stelling is bijgewerkt");
		}),
	}))
	.views((self) => ({
		get questionsString() {
			if (self.questions.length === 0) return "";

			return Object.values(self.questions)
				.map((item) => item.answer)
				.join(";");
		},
	}));

export interface StatementInstance extends Instance<typeof StatementModel> {}
export interface StatementSnapshot extends SnapshotIn<typeof StatementModel> {}

export default StatementModel;
