import { types, Instance, flow, destroy } from "mobx-state-tree";
import FragmentModel, { FragmentSnapshot } from "./FragmentModel";
import { FragmentService } from "services";
import RequestFiltersModel from "stores/Models/RequestFilters";
import alertUtil from "util/alertUtil";

const FragmentStore = types
  .model({
    fragments: types.array(FragmentModel),
    current: types.maybeNull(types.reference(FragmentModel)),
    filters: RequestFiltersModel,
  })
  .actions((self) => ({
    load: flow(function* (startRoomId: number, personaId: number) {
      const response = yield FragmentService.listWithIncludes(
        self.filters,
        startRoomId,
        personaId
      );

      self.fragments = response.results;
      self.filters.update(response);
    }),
    get: flow(function* (id: number) {
      const fragment = yield FragmentService.singleWithIncludes(id);
      if (fragment) self.fragments.push(fragment);
      return fragment;
    }),
    reset: () => {
      destroy(self.fragments);
      self.filters = RequestFiltersModel.create();
    },
    create: flow(function* (
      fragmentData: Partial<FragmentSnapshot>,
      files: any
    ) {
      const fragment = yield FragmentService.createWithIncludes(
        fragmentData,
        files
      );

      if (fragment) {
        self.fragments.push(fragment);
        alertUtil.success("Het fragment is aangemaakt");
      }
    }),
    delete: flow(function* () {
      const result = yield FragmentService.delete(self.current.id);
      self.current = null;
      if (result) self.fragments.remove(self.current);
    }),
  }))
  .actions((self) => ({
    setCurrent: flow(function* (id: number) {
      if (!self.fragments.some((fragment) => fragment.id === id)) {
        const fragment = yield self.get(id);
        if (!fragment) return false;
      }

      self.current = id as any;
      return true;
    }),
    unsetCurrent: () => {
      self.current = null;
    },
  }));

export default FragmentStore;
export interface FragmentStoreInstance extends Instance<typeof FragmentStore> {}
