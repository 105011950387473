import {
	types,
	Instance,
	SnapshotIn,
	applySnapshot,
	flow,
} from "mobx-state-tree";
import { FaqService } from "services";
import alertUtil from "util/alertUtil";

const FaqModel = types
	.model("FaqItemModel", {
		id: types.identifierNumber,
		question: types.string,
		answer: types.string,
		category: types.string,
	})
	.actions((self) => ({
		update: flow(function* (faqData: Partial<SnapshotIn<typeof FaqModel>>) {
			const faq = yield FaqService.update(self.id, faqData);

			if (faq) {
				applySnapshot(self, faq);
				alertUtil.success("De vraag is bijgewerkt");
			}
		}),
	}))
	.views((self) => ({
		get formattedCategory() {
			switch (self.category) {
				default:
				case "GENERAL":
					return "Algemeen";
				case "TICKETS":
					return "Tickets";
				case "PERFORMANCES":
					return "Bezoek Huis van Sarah";
			}
		},
	}));

export interface FaqInstance extends Instance<typeof FaqModel> {}
export interface FaqSnapshot extends SnapshotIn<typeof FaqModel> {}

export default FaqModel;
