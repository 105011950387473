import React from "react";
import { Form } from "react-bootstrap";
import { observer } from "mobx-react-lite";

const RadioButtonGroup = ({
  label,
  id,
  options,
  onChange,
  defaultValues,
}: {
  label: string;
  id: string;
  options: { id: string; label: string }[];
  onChange: (checked: boolean, name: string) => void;
  defaultValues?: any[];
}) => {
  return (
    <Form.Group>
      <label htmlFor={id}>{label}</label>
      {options.map((option) => (
        <Form.Check
          type="checkbox"
          id={option.id}
          label={option.label}
          defaultChecked={defaultValues?.some((id) => id === option.id)}
          onChange={(e) => onChange(e.target.checked, option.id)}
          className="form-check-input"
        />
      ))}
    </Form.Group>
  );
};

export default observer(RadioButtonGroup);
