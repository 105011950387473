import { types, flow, destroy } from "mobx-state-tree";
import EmployeeModel, { EmployeeSnapshot } from "./EmployeeModel";
import EmployeeService from "services/EmployeeService";
import RequestFiltersModel from "stores/Models/RequestFilters";
import alertUtil from "util/alertUtil";

const EmployeeStore = types
  .model({
    employees: types.array(EmployeeModel),
    current: types.maybeNull(types.reference(EmployeeModel)),
    filters: RequestFiltersModel,
  })
  .actions((self) => ({
    load: flow(function* () {
      const response = yield EmployeeService.listWithIncludes(self.filters);
      self.employees = response.results;
      self.filters.update(response);
    }),
    search: flow(function* () {
      const response = yield EmployeeService.listWithIncludes(self.filters);
      self.employees = response.results;
    }),
    get: flow(function* (id: number) {
      const employee = yield EmployeeService.singleWithIncludes(id);

      if (employee) {
        self.employees.push(employee);
        return true;
      }

      return false;
    }),
    reset: () => {
      destroy(self.employees);
    },
    create: flow(function* (
      employeeData: Partial<EmployeeSnapshot>,
      files: any
    ) {
      const employee = yield EmployeeService.createWithIncludes(
        employeeData,
        files
      );

      if (employee) {
        self.employees.push(employee);
        alertUtil.success("De medewerker is aangemaakt");
      }
    }),
  }))
  .actions((self) => ({
    setCurrent: flow(function* (id: number) {
      var result = true;

      if (!self.employees.some((employee) => employee.id === id)) {
        result = yield self.get(id);
      }

      if (result) {
        self.current = id as any;
      }

      return result;
    }),

    unsetCurrent: () => {
      self.current = null;
    },
  }));

export default EmployeeStore;
