import CrudService from "./CrudService";
import PaginatedResponse from "stores/Models/PaginatedResponse";
import { RequestFiltersSnapshot } from "stores/Models/RequestFilters";
import { EmployeeSnapshot } from "stores/EmployeeStore/EmployeeModel";

class EmployeeService extends CrudService<EmployeeSnapshot> {
  listWithIncludes = async (
    filters: RequestFiltersSnapshot
  ): Promise<PaginatedResponse<EmployeeSnapshot>> => {
    const response = await this.list(filters);

    response.results = response.results.map((employee) => {
      return {
        ...employee,
        event: employee.eventId,
      };
    });

    return response;
  };

  singleWithIncludes = async (id: number): Promise<EmployeeSnapshot> => {
    var employee = await this.single(id);

    return this.transform(employee);
  };

  createWithIncludes = async (
    employeeData: Partial<EmployeeSnapshot>,
    files: any
  ): Promise<EmployeeSnapshot> => {
    var employee = await this.create(employeeData, files);

    return this.transform(employee);
  };

  updateWithIncludes = async (
    id: number,
    employeeData: Partial<EmployeeSnapshot>,
    files: any
  ): Promise<EmployeeSnapshot> => {
    var employee = await this.update(id, employeeData, files);
    return this.transform(employee);
  };

  transform = (employee: EmployeeSnapshot): EmployeeSnapshot => {
    if (employee) employee.event = employee.eventId;
    return employee;
  };
}

export default new EmployeeService("/employees");
