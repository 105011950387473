import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

import "./ReactotronConfig";
import * as Sentry from "@sentry/browser";

if (process.env.NODE_ENV === "production")
  Sentry.init({
    dsn:
      "https://0a6546f6327c4178a23aa24cab927f43@o178697.ingest.sentry.io/5385387",
  });

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
