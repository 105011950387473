import React, { useState } from "react";
import { OverviewPage } from "pages";
import { RootStore } from "stores";
import { TicketForm, TicketGroupForm } from "components/forms";
import CustomModal from "components/CustomModal";
import { TicketInstance } from "stores/UserStore/TicketModel";
import { Button, ButtonGroup } from "react-bootstrap";
import ConfirmModal from "components/ConfirmModal";
import { PerformanceService } from "services";
import { saveAs } from "file-saver";
import { Link } from "react-router-dom";
import { observer } from "mobx-react-lite";

const PerformanceAttendanceOverview = () => {
  const individualTickets =
    RootStore.PerformanceStore.current?.IndividualTickets;

  const partnerTickets = RootStore.PerformanceStore.current?.PartnerTickets;
  const groupTickets = RootStore.PerformanceStore.current?.GroupTickets;

  const [ticket, setTicket] = useState<TicketInstance>();
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showPincodeModal, setShowPincodeModal] = useState(false);
  const [showDeleteGroupModal, setShowDeleteGroupModal] = useState(false);
  const [pincode, setPincode] = useState();

  const defaultStartColumns: { dataField; text; formatter? }[] = [
    {
      dataField: "user",
      text: "Naam",
      formatter: function Formatter(_cell) {
        return !_cell ? <i>Niet geclaimd</i> : _cell;
      },
    },
  ];

  const defaultEndColumns: { dataField; text; formatter? }[] = [
    {
      dataField: "initialRoomId",
      text: "Toegewezen aan kamer",
      formatter: function Formatter(_cell) {
        if (_cell) {
          return RootStore.RoomStore.rooms
            .slice()
            .find((room) => room.id === _cell).name;
        }

        return <i>Niet gescand</i>;
      },
    },
    // 2021-10-21 Commented because dietary requirements functionality is temporarily disabled
    // {
    //   dataField: "dietaryRequirement",
    //   text: "Dieetwensen",
    //   formatter: function Formatter(_cell, _row) {
    //     return _row.dietaryRequirement ? (
    //       _row.dietaryRequirement.ViewData.join(", ")
    //     ) : (
    //       <i>Geen dieetwensen</i>
    //     );
    //   },
    // },
    {
      dataField: "date",
      text: "Aanmaakdatum",
    },
    {
      dataField: "scannedAt",
      text: "Gescand",
      formatter: function Formatter(scannedAt, row) {
        return scannedAt ? (
          "Ja"
        ) : !!row.object.user ? (
          <Button
            onClick={() => {
              row.object.scan();
            }}
          >
            Scannen
          </Button>
        ) : <></>;
      },
    },
  ];

  const individualColumns = defaultStartColumns.concat(defaultEndColumns);

  const partnerColumns = defaultStartColumns
    .concat([
      {
        dataField: "partnerType",
        text: "Relatie",
      },
      {
        dataField: "inviter",
        text: "Uitgenodigd door",
        formatter: function Formatter(_cell, row) {
          return <Link to={`/gebruikers/${row.inviterId}`}>{_cell}</Link>;
        },
      },
    ])
    .concat(defaultEndColumns);

  const groupColumns = defaultStartColumns.concat(defaultEndColumns);

  const onView = (ticket) => {
    setTicket(ticket.object);
    setShowDetailModal(true);
  };

  const onTicketRemove = async (id: number) => {
    await RootStore.PerformanceStore.current.deleteTicket(id);
    setShowDetailModal(false);
  };

  const onTicketGroupRemove = async () => {
    if (pincode) {
      await RootStore.PerformanceStore.current.deleteTicketGroup(pincode);
    }

    setShowDeleteGroupModal(false);
  };

  const onCreateGroup = async (groupData: {
    amount: number;
    inviter: string;
    performanceId: number;
  }) => {
    const pincode = await RootStore.PerformanceStore.current.createGroup(
      groupData
    );

    setShowCreateModal(false);
    setPincode(pincode);
    setShowPincodeModal(true);
  };

  const exportAttendees = async () => {
    const _export = await PerformanceService.export(
      RootStore.PerformanceStore.current?.id
    );

    if (_export)
      saveAs(
        new Blob([_export], { type: "text/csv" }),
        `Bezoekerslijst HvS ${RootStore.PerformanceStore.current?.Date}.csv`
      );
  };

  const exportScannedAttendees = async () => {
    const _export = await PerformanceService.exportScannedAttendees(
      RootStore.PerformanceStore.current?.id
    );

    if (_export)
      saveAs(
        new Blob([_export], { type: "text/csv" }),
        `Bezoekerslijst (gescande tickets) HvS ${RootStore.PerformanceStore.current?.Date}.csv`
      );
  };

  const exportHelpQuestions = async () => {
    const _export = await PerformanceService.exportHelpQuestions(
      RootStore.PerformanceStore.current?.id
    );

    if (_export)
      saveAs(
        new Blob([_export], { type: "text/csv" }),
        `Hulpvragen HvS ${RootStore.PerformanceStore.current?.Date}.csv`
      );
  };

  return (
    <>
      <ButtonGroup className="mb-4">
        {RootStore.User.IsAdmin &&
          !RootStore.PerformanceStore.current?.deletedAt && (
            <Button
              onClick={() => setShowCreateModal(true)}
              disabled={RootStore.PerformanceStore.current?.available <= 0}
            >
              Groep aanmaken
            </Button>
          )}
        <Button onClick={() => exportAttendees()} variant="light">
          Exporteren bezoekerslijst
        </Button>
        <Button onClick={() => exportHelpQuestions()} variant="secondary">
          Exporteren hulpvragen
        </Button>
        <Button onClick={() => exportScannedAttendees()} variant="light">
          Exporteren bezoekers (gescande tickets)
        </Button>
      </ButtonGroup>

      {individualTickets && (
        <div className="row mb-5">
          <div className="col-lg-12">
            <OverviewPage
              name="Bezoekers"
              columns={individualColumns}
              items={individualTickets}
              onView={(ticket) => onView(ticket)}
            ></OverviewPage>
          </div>
        </div>
      )}

      {partnerTickets && (
        <div className="row mb-5">
          <div className="col-lg-12">
            <OverviewPage
              name="Uitnodigingen"
              columns={partnerColumns}
              items={partnerTickets}
              onView={(ticket) => onView(ticket)}
            ></OverviewPage>
          </div>
        </div>
      )}

      {groupTickets &&
        Object.keys(groupTickets).map((key) => {
          const tickets = groupTickets[key];
          const available = tickets.filter((ticket) => ticket.user).length;
          const total = tickets.length;
          const inviter = key.substring(0, key.indexOf("_"));
          return (
            <div className="row mb-5">
              <div className="col-lg-12">
                <OverviewPage
                  name={`${inviter} (${available} / ${total} geclaimd)`}
                  columns={groupColumns}
                  items={tickets}
                  onView={(ticket) => onView(ticket)}
                ></OverviewPage>

                {RootStore.User.IsAdmin && (
                  <ButtonGroup className="mt-3">
                    <Button
                      variant="danger"
                      onClick={() => {
                        setPincode(tickets[0].object.pincode);
                        setShowDeleteGroupModal(true);
                      }}
                    >
                      Groep verwijderen
                    </Button>
                  </ButtonGroup>
                )}
              </div>
            </div>
          );
        })}

      <CustomModal
        modalTitle="Ticket beheren"
        visible={showDetailModal}
        onHide={() => setShowDetailModal(false)}
      >
        <TicketForm ticket={ticket} onRemoveClick={onTicketRemove}></TicketForm>
      </CustomModal>

      <ConfirmModal
        show={showDeleteGroupModal}
        onHide={() => setShowDeleteGroupModal(false)}
        onConfirm={onTicketGroupRemove}
        modalText="Weet je zeker dat je deze groep wilt verwijderen?"
        modalTitle={"Groep verwijderen"}
      ></ConfirmModal>

      <CustomModal
        modalTitle="Groep aanmaken"
        visible={showCreateModal}
        onHide={() => setShowCreateModal(false)}
      >
        <TicketGroupForm
          performance={RootStore.PerformanceStore.current}
          onSubmitCallback={onCreateGroup}
        ></TicketGroupForm>
      </CustomModal>

      <CustomModal
        modalTitle="Groep aangemaakt"
        visible={showPincodeModal}
        onHide={() => setShowPincodeModal(false)}
      >
        <div className="text-center">
          Deel de volgende pincode met de organisatie/uitnodiger:
          <h4>{pincode}</h4>
        </div>
      </CustomModal>
    </>
  );
};

export default observer(PerformanceAttendanceOverview);
