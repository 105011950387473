import React, { CSSProperties } from "react";

const Loader = ({
  display,
  position,
  style,
  size = 30,
}: {
  display: boolean;
  position: "left" | "right";
  style?: CSSProperties;
  size?: number;
}) => {
  return (
    <div
      style={{
        position: "absolute",
        top: "-3px",
        [position]: "-30px",
        ...style,
      }}
    >
      {display ? (
        <img
          width={size + "px"}
          height={size + "px"}
          src={require("../assets/images/loader.svg")}
          alt="Loading"
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default Loader;
