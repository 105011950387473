import { types, Instance, SnapshotIn } from "mobx-state-tree";
import PaginatedResponse from "./PaginatedResponse";

const RequestFiltersModel = types
  .model({
    page: 1,
    limit: 20,
    totalPages: types.maybe(types.number),
    total: types.maybe(types.number),
    searchPhrase: "",
    sortBy: "createdAt",
    sortDirection: 1,
  })
  .actions((self) => ({
    setPage: (page: number) => {
      self.page = page;
    },
    setLimit: (limit: number) => {
      self.limit = limit;
      self.page = 1;
    },
    setSearchPhrase: (searchPhrase: string, noLimit: boolean = false) => {
      self.searchPhrase = searchPhrase;
      self.page = 1;

      if (noLimit) {
        self.limit = Math.max();
      }
    },
    setSortBy: (field: string, resetPage: boolean = true) => {
      self.sortBy = field;
      if (resetPage) self.page = 1;
    },
    setSortDirection: (direction: 1 | 2, resetPage: boolean = true) => {
      self.sortDirection = direction;
      if (resetPage) self.page = 1;
    },
    update: (paginatedResponse: PaginatedResponse<void>) => {
      self.totalPages = paginatedResponse.totalPages;
      self.total = paginatedResponse.total;
    },
  }));

export interface RequestFiltersInstance
  extends Instance<typeof RequestFiltersModel> {}

export interface RequestFiltersSnapshot
  extends SnapshotIn<typeof RequestFiltersModel> {}

export default RequestFiltersModel;
