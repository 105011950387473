import CrudService from "./CrudService";
import { LocationSnapshot } from "stores/LocationStore/LocationModel";
import PaginatedResponse from "stores/Models/PaginatedResponse";
import { RequestFiltersSnapshot } from "stores/Models/RequestFilters";

class LocationService extends CrudService<LocationSnapshot> {
  listWithIncludes = async (
    filters: RequestFiltersSnapshot
  ): Promise<PaginatedResponse<LocationSnapshot>> => {
    const response = await this.list(filters);

    response.results = response.results.map((location) => {
      return this.transform(location);
    });

    return response;
  };

  singleWithIncludes = async (id: number): Promise<LocationSnapshot> => {
    var location = await this.single(id);
    return this.transform(location);
  };

  updateWithIncludes = async (
    id: number,
    location: LocationSnapshot
  ): Promise<LocationSnapshot> => {
    location = await this.update(id, location);
    return this.transform(location);
  };

  createWithIncludes = async (
    location: LocationSnapshot
  ): Promise<LocationSnapshot> => {
    location = await this.create(location);
    return this.transform(location);
  };

  transform = (location: LocationSnapshot): LocationSnapshot => {
    if (location) location.event = location.eventId;
    return location;
  };
}
export default new LocationService("/locations");
