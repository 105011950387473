import { types, flow, destroy } from "mobx-state-tree";
import PostModel, { PostSnapshot } from "./PostModel";
import PostService from "services/PostService";
import RequestFiltersModel from "stores/Models/RequestFilters";
import alertUtil from "util/alertUtil";

const PostStore = types
  .model({
    posts: types.array(PostModel),
    current: types.maybeNull(types.reference(PostModel)),
    filters: RequestFiltersModel,
  })
  .actions((self) => ({
    load: flow(function* () {
      const response = yield PostService.listWithIncludes(self.filters);
      self.posts = response.results;
      self.filters.update(response);
    }),
    get: flow(function* (id: number) {
      const post = yield PostService.singleWithIncludes(id);

      if (post) {
        self.posts.push(post);
        return true;
      }

      return false;
    }),
    reset: () => {
      destroy(self.posts);
    },
    create: flow(function* (postData: Partial<PostSnapshot>, files: any) {
      const post = yield PostService.createWithIncludes(postData, files);

      if (post) {
        self.posts.push(post);
        alertUtil.success("De medewerker is aangemaakt");
      }
    }),
    delete: flow(function* () {
      const result = yield PostService.delete(self.current.id);
      self.current = null;
      if (result) self.posts.remove(self.current);
    }),
  }))
  .actions((self) => ({
    setCurrent: flow(function* (id: number) {
      var result = true;

      if (!self.posts.some((post) => post.id === id)) {
        result = yield self.get(id);
      }

      if (result) {
        self.current = id as any;
      }

      return result;
    }),

    unsetCurrent: () => {
      self.current = null;
    },
  }));

export default PostStore;
