import React, { useState } from "react";
import { RootStore } from "stores";
import { observer } from "mobx-react-lite";
import { OverviewPage } from "pages/Base";
import { PostSnapshot } from "stores/PostStore/PostModel";
import { PostForm } from "components/forms";
import { CheckInput } from "components/forms/FormElements";
import { Link } from "react-router-dom";

const PostOverviewPage = () => {
  const columns = [
    {
      dataField: "title",
      text: "Titel",
    },
    {
      dataField: "type",
      text: "Type",
      formatter: function Formatter(_cell, row) {
        return _cell === "NEWS" ? "Tekstbericht" : "Video";
      },
    },
    {
      dataField: "phase",
      text: "Fase",
      formatter: function Formatter(_cell, row) {
        return _cell === "PRE_PERFORMANCE"
          ? "Voor voorstelling"
          : _cell === "POST_PERFORMANCE"
          ? "Na voorstelling"
          : "Altijd";
      },
    },
    {
      dataField: "link",
      text: "Koppeling",
      formatter: function Formatter(_cell, row) {
        return _cell.length > 0 ? (
          <span>
            Gekoppeld aan{" "}
            <Link
              to={
                row.roomId
                  ? `/kamers/${row.roomId}`
                  : `/medewerkers/${row.employeeId}`
              }
            >
              {_cell}
            </Link>
          </span>
        ) : (
          <i>Niet gekoppeld</i>
        );
      },
    },
    {
      dataField: "active",
      text: "Actief",
      formatter: function Formatter(_cell, row) {
        return (
          <CheckInput
            key={row.id}
            id={"active" + row.id}
            defaultValue={_cell}
            onChange={async (checked: boolean) => {
              await RootStore.PostStore.setCurrent(row.id);
              await RootStore.PostStore.current.setActive(checked);
            }}
          ></CheckInput>
        );
      },
    },
    {
      dataField: "featured",
      text: "Uitgelicht",
      formatter: function Formatter(_cell, row) {
        return (
          <CheckInput
            id={"featured" + row.id}
            defaultValue={_cell}
            onChange={async (checked: boolean) => {
              await RootStore.PostStore.setCurrent(row.id);
              await RootStore.PostStore.current.setFeatured(checked);
            }}
          ></CheckInput>
        );
      },
    },
  ];

  const sortableFields = [
    {
      value: "title",
      label: "Titel",
      defaultAscending: true,
    },
  ];

  const [showModal, setShowModal] = useState<boolean>();

  const onCreateSubmit = async (post: Partial<PostSnapshot>, files: any) => {
    await RootStore.PostStore?.create(post, files);
    setShowModal(false);
  };

  const form = <PostForm onSubmitCallback={onCreateSubmit}></PostForm>;

  return (
    <OverviewPage
      name="Berichten"
      singular="Bericht"
      path="berichten"
      onLoad={() => RootStore.PostStore.load()}
      filters={RootStore.PostStore.filters}
      columns={columns}
      items={RootStore.PostStore.posts}
      form={form}
      showModal={showModal}
      sortableFields={sortableFields}
    ></OverviewPage>
  );
};

export default observer(PostOverviewPage);
