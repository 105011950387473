import React, { useState } from "react";
import { RootStore } from "stores";
import { FragmentForm } from "components/forms";
import {
  FragmentSnapshot,
  FragmentInstance,
} from "stores/FragmentStore/FragmentModel";
import DetailPage from "pages/Base/DetailPage";
import { observer } from "mobx-react-lite";
import { useHistory } from "react-router";
import { ConfirmModal } from "components";

const FragmentDetailPage = ({ match }: { match: { params } }) => {
  const history = useHistory();

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const onEditSubmit = async (
    fragmentData: Partial<FragmentSnapshot>,
    files: any
  ) => {
    await RootStore.FragmentStore.current?.update(fragmentData, files);
  };

  const onRemoveSubmit = async () => {
    await RootStore.FragmentStore.delete();
    history.push("/fragmenten");
  };

  return (
    <>
      <DetailPage<FragmentInstance>
        current={RootStore.FragmentStore.current}
        store={RootStore.FragmentStore}
        title={RootStore.FragmentStore.current?.title}
        form={
          <FragmentForm
            onSubmitCallback={onEditSubmit}
            onRemoveCallback={() => setShowDeleteModal(true)}
            fragment={RootStore.FragmentStore.current}
          ></FragmentForm>
        }
        match={match}
      ></DetailPage>

      <ConfirmModal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        onConfirm={onRemoveSubmit}
        modalText="Weet je zeker dat je dit fragment wilt verwijderen?"
        modalTitle={"Fragment verwijderen"}
      ></ConfirmModal>
    </>
  );
};

export default observer(FragmentDetailPage);
