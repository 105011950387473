import { types, SnapshotIn, Instance, flow, destroy } from "mobx-state-tree";
import HelpQuestionAnswerModel from "./HelpQuestionAnswerModel";
import { HelpQuestionAnswerService } from "services";
import moment from "moment";
import RequestFiltersModel from "stores/Models/RequestFilters";

const HelpQuestionAnswerStore = types
  .model("HelpQuestionAnswerStore", {
    helpQuestionAnswers: types.array(HelpQuestionAnswerModel),
    filters: RequestFiltersModel,
    current: types.maybeNull(types.reference(HelpQuestionAnswerModel)),
  })
  .actions((self) => ({
    load: flow(function* (date: Date = null, includeArchive : boolean = false) {
      const response = yield HelpQuestionAnswerService.listWithIncludes(
        self.filters,
        date ? moment(date).format("yyyy-MM-DD") : null,
        includeArchive
      );

      if(date) {
        self.filters = RequestFiltersModel.create();
      }

      self.helpQuestionAnswers = response.results;
    }),
    get: flow(function* (id: number) {
      const helpQuestionAnswer = yield HelpQuestionAnswerService.single(id);
      if (helpQuestionAnswer) self.helpQuestionAnswers.push(helpQuestionAnswer);
      return helpQuestionAnswer;
    }),
    reset: () => {
      destroy(self.helpQuestionAnswers);
      self.filters = RequestFiltersModel.create();
    },
  }))
  .actions((self) => ({
    setCurrent: flow(function* (id: number) {
      if (
        !self.helpQuestionAnswers.some(
          (helpQuestionAnswer) => helpQuestionAnswer.id === id
        )
      ) {
        const helpQuestionAnswer = yield self.get(id);
        if (!helpQuestionAnswer) return false;
      }

      self.current = id as any;
      return true;
    }),
    unsetCurrent: () => {
      self.current = null;
    },
  }))
  .views((self) => ({
    get splitPerUser() {
      return self.helpQuestionAnswers.reduce((result, answer) => {
        const ticketId = answer.ticketId;

        if (!result[ticketId]) result[ticketId] = [];

        result[ticketId].push(answer);
        return result;
      }, []);
    },
    get splitPerQuestion() {
      return self.helpQuestionAnswers.reduce((result, answer) => {
        const helpQuestionId = answer.helpQuestionId;

        if (!result[helpQuestionId]) result[helpQuestionId] = [];

        result[helpQuestionId].push(answer);
        return result;
      }, []);
    },
  }));

export interface HelpQuestionAnswerStoreSnapshot
  extends SnapshotIn<typeof HelpQuestionAnswerStore> {}
export interface HelpQuestionAnswerStoreInstance
  extends Instance<typeof HelpQuestionAnswerStore> {}

export default HelpQuestionAnswerStore;
