import { types, SnapshotIn, Instance } from "mobx-state-tree";

const QuestionModel = types.model({
  id: types.identifierNumber,
  answer: types.string,
  votes: types.number,
});

export interface QuestionSnapshot extends SnapshotIn<typeof QuestionModel> {}
export interface QuestionInstance extends Instance<typeof QuestionModel> {}

export default QuestionModel;
