import CrudService from "./CrudService";
import { FragmentSnapshot } from "stores/FragmentStore/FragmentModel";
import PaginatedResponse from "stores/Models/PaginatedResponse";
import { RequestFiltersSnapshot } from "stores/Models/RequestFilters";

class FragmentService extends CrudService<FragmentSnapshot> {
  listWithIncludes = async (
    filters: RequestFiltersSnapshot,
    startRoomId: number,
    personaId: number
  ): Promise<PaginatedResponse<FragmentSnapshot>> => {
    const response = await this.list(filters, { startRoomId, personaId });
    response.results = response.results.map((fragment) => {
      return this.transform(fragment);
    });

    return response;
  };

  singleWithIncludes = async (id: number): Promise<FragmentSnapshot> => {
    var fragment = await this.single(id);
    return this.transform(fragment);
  };

  createWithIncludes = async (
    fragmentData: Partial<FragmentSnapshot>,
    files: any
  ): Promise<FragmentSnapshot> => {
    var fragment = await this.create(fragmentData, files);

    return this.transform(fragment);
  };

  updateWithIncludes = async (
    id: number,
    fragmentData: Partial<FragmentSnapshot>,
    files: any
  ): Promise<FragmentSnapshot> => {
    var fragment = await this.update(id, fragmentData, files);
    return this.transform(fragment);
  };

  transform = (fragment: FragmentSnapshot): FragmentSnapshot => {
    if (fragment) {
      fragment.room = fragment.roomId;
      fragment.persona = fragment.personaId;
      fragment.startRoom = fragment.startRoomId;
    }
    return fragment;
  };
}

export default new FragmentService("/fragments");
