import { types, Instance, flow, destroy } from "mobx-state-tree";

import FaqModel, { FaqSnapshot } from "./FaqModel";
import FaqService from "services/FaqService";
import RequestFiltersModel from "stores/Models/RequestFilters";
import alertUtil from "util/alertUtil";

const FaqStore = types
  .model("FaqStoreModel", {
    items: types.array(FaqModel),
    filters: RequestFiltersModel,
    current: types.maybeNull(types.reference(FaqModel)),
  })
  .actions((self) => ({
    load: flow(function* () {
      const response = yield FaqService.list(self.filters);
      self.items = response.results;
      self.filters.update(response);
    }),
    get: flow(function* (id: number) {
      const faq = yield FaqService.single(id);
      if (faq) self.items.push(faq);
      return faq;
    }),
    reset: () => {
      destroy(self.items);
      self.filters = RequestFiltersModel.create();
    },
    create: flow(function* (faqData: Partial<FaqSnapshot>) {
      const faq = yield FaqService.create(faqData);

      if (faq) {
        self.items.push(faq);
        alertUtil.success("De vraag is aangemaakt");
      }
    }),
    delete: flow(function* () {
      const result = yield FaqService.delete(self.current.id);
      self.current = null;
      if (result) self.items.remove(self.current);
    }),
  }))
  .actions((self) => ({
    setCurrent: flow(function* (id: number) {
      if (!self.items.some((faq) => faq.id === id)) {
        const faq = yield self.get(id);
        if (!faq) return false;
      }

      self.current = id as any;
      return true;
    }),

    unsetCurrent: () => {
      self.current = null;
    },
  }))
  .views((self) => ({}));

export interface FaqStoreInstance extends Instance<typeof FaqStore> {}

export default FaqStore;
