import {
	types,
	Instance,
	flow,
	applySnapshot,
	SnapshotIn,
} from "mobx-state-tree";
import alertUtil from "util/alertUtil";
import HelpQuestionService from "services/HelpQuestionService";

const HelpQuestionModel = types
	.model("HelpQuestionModel", {
		id: types.identifierNumber,
		title: types.maybeNull(types.string),
		question: types.string,
		default: types.boolean,
		order: types.number,
		deletedAt: types.maybeNull(types.string),
	})
	.actions((self) => ({
		update: flow(function* (
			helpQuestionData: Partial<SnapshotIn<typeof HelpQuestionModel>>
		) {
			const helpQuestion = yield HelpQuestionService.update(self.id, {
				id: self.id,
				...helpQuestionData,
			});

			applySnapshot(self, helpQuestion);
			alertUtil.success("De hulpvraag is bijgewerkt");
		}),
	}));

export interface HelpQuestionInstance
	extends Instance<typeof HelpQuestionModel> {}

export interface HelpQuestionSnapshot
	extends SnapshotIn<typeof HelpQuestionModel> {}

export default HelpQuestionModel;
