import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import Loader from "components/Loader";
import {
  LocationSnapshot,
  LocationInstance,
} from "stores/LocationStore/LocationModel";
import { TextInput, CheckboxGroup } from "./FormElements";
import { RootStore } from "stores";

const LocationForm = ({
  onSubmitCallback,
  location,
}: {
  onSubmitCallback: (locationData: LocationSnapshot) => Promise<void>;
  location?: LocationInstance;
}) => {
  const roomOptions = RootStore.RoomStore.rooms.map((room) => {
    return { id: room.id, label: room.name };
  });

  const onSubmit = async (formEvent) => {
    setLoading(true);

    formEvent.preventDefault();

    const locationData: any = {
      name: formEvent.target[1].value,
      street: formEvent.target[2].value,
      houseNumber: formEvent.target[3].value,
      zipCode: formEvent.target[4].value,
      city: formEvent.target[5].value,
      country: formEvent.target[6].value,
      eventId: location ? location.eventId : RootStore.currentEvent.id,
      rooms: rooms.map((id) => {
        return {
          roomId: id,
        };
      }),
    };

    if (location) locationData.id = location.id;

    await onSubmitCallback(locationData);

    setLoading(false);
  };

  let rooms: number[] =
    location && location.rooms
      ? location.rooms.map((locationRoom) => locationRoom.roomId)
      : [];

  const [loading, setLoading] = useState(false);

  return (
    <Form onSubmit={onSubmit}>
      <fieldset disabled={!RootStore.User.IsAdmin}>
        <div className="row">
          <div
            className={
              RootStore.currentEvent.isTheater ? "col-md-6" : "col-md-12"
            }
          >
            <TextInput
              id="name"
              label="Naam"
              placeholder="Naam"
              defaultValue={location?.name}
              required={true}
            ></TextInput>

            <TextInput
              id="street"
              label="Straat"
              placeholder="Straat"
              defaultValue={location?.street}
              required={true}
            ></TextInput>

            <TextInput
              id="houseNumber"
              label="Huisnummer"
              placeholder="Huisnummer"
              defaultValue={location?.houseNumber}
              required={true}
            ></TextInput>

            <TextInput
              id="zipCode"
              label="Postcode"
              placeholder="Postcode"
              defaultValue={location?.zipCode}
              required={true}
            ></TextInput>

            <TextInput
              id="city"
              label="Plaats"
              placeholder="Plaats"
              defaultValue={location?.city}
              required={true}
            ></TextInput>

            <TextInput
              id="country"
              label="Land"
              placeholder="Land"
              defaultValue={location?.country}
              required={true}
            ></TextInput>
          </div>

          {RootStore.currentEvent.isTheater && (
            <div className="col-md-6">
              <CheckboxGroup
                id="locationRooms"
                label="Kamers"
                options={roomOptions}
                defaultValues={rooms}
                onChange={(checked, value) => {
                  var id = Number(value);
                  if (checked) rooms.push(id);
                  else {
                    rooms.splice(rooms.indexOf(id), 1);
                  }
                }}
              ></CheckboxGroup>
            </div>
          )}
        </div>

        <div className="row">
          <div className="col-lg-12">
            <Button
              type="submit"
              className="btn btn-primary mr-2 position-relative"
            >
              <Loader position="right" display={loading}></Loader>
              {location ? "Save" : "Add"}
            </Button>
          </div>
        </div>
      </fieldset>
    </Form>
  );
};

export default LocationForm;
