import React, { useEffect, useState } from "react";
import { RootStore } from "stores";
import { observer } from "mobx-react-lite";
import { OverviewPage } from "pages/Base";
import { PerformanceSnapshot } from "stores/PerformanceStore/PerformanceModel";
import { PerformanceForm } from "components/forms";
import moment from "moment";
import { Link } from "react-router-dom";
import { CheckInput } from "components/forms/FormElements";

const PerformancesOverviewPage = () => {
  const columns = [
    {
      dataField: "date",
      text: "Datum",
      formatter: function Formatter(_cell, row) {
        const value = moment(_cell).format("DD-MM-YYYY");
        if (row.deletedAt) {
          return (
            <span style={{ textDecoration: "line-through" }}>{value}</span>
          );
        } else return value;
      },
    },
    {
      dataField: "locationName",
      text: "Locatie",
      formatter: function Formatter(_cell, row) {
        return <Link to={`locaties/${row.locationId}`}>{_cell}</Link>;
      },
    },
    {
      dataField: "from",
      text: "Begintijd",
    },
    {
      dataField: "to",
      text: "Eindtijd",
    },
    {
      dataField: "Available",
      text: "Plaatsen over",
    },
  ];

  const sortableFields = [
    {
      label: "Datum",
      value: "date",
      defaultAscending: false,
    },
  ];

  const [showModal, setShowModal] = useState<boolean>();
  const [includeArchive, setIncludeArchive] = useState<boolean>();

  const onCreateSubmit = async (persona: Partial<PerformanceSnapshot>) => {
    await RootStore.PerformanceStore?.create(persona);
    setShowModal(false);
  };

  const onCheckIncludeArchive = async (active: boolean) => {
    setIncludeArchive(active);
  };

  const form = (
    <PerformanceForm onSubmitCallback={onCreateSubmit}></PerformanceForm>
  );

  const customFilters = (
    <CheckInput
      id="includeArchive"
      label="Geannuleerde voorstellingen tonen"
      defaultValue={false}
      key="includeArchive"
      onChange={onCheckIncludeArchive}
    ></CheckInput>
  );

  useEffect(() => {
    RootStore.PerformanceStore.load(includeArchive);
  }, [includeArchive]);

  return (
    <>
      <div className="row mb-5">
        <div className="col-lg-12">
          <OverviewPage
            name="Voorstellingen"
            singular="Voorstelling"
            columns={columns}
            onLoad={() => RootStore.PerformanceStore.load(includeArchive)}
            items={RootStore.PerformanceStore.performances}
            filters={RootStore.PerformanceStore.filters}
            search={false}
            sort={true}
            sortableFields={sortableFields}
            path={`voorstellingen`}
            showModal={showModal}
            form={form}
            customFilters={customFilters}
          ></OverviewPage>
        </div>
      </div>
    </>
  );
};

export default observer(PerformancesOverviewPage);
