import {
	types,
	Instance,
	SnapshotIn,
	flow,
	applySnapshot,
} from "mobx-state-tree";
import { PerformanceService, TicketService } from "services";
import TicketModel from "stores/UserStore/TicketModel";
import alertUtil from "util/alertUtil";
import moment from "moment";

const PerformanceModel = types
	.model("PerformanceModel", {
		id: types.identifierNumber,
		date: types.string,
		from: types.string,
		to: types.string,
		maxVisitors: types.number,
		available: types.number,
		published: types.boolean,
		locationId: types.number,
		locationName: types.maybe(types.string),
		tickets: types.array(TicketModel),
		deletedAt: types.maybeNull(types.string),
	})
	.actions((self) => ({
		update: flow(function* (
			performanceData: Partial<SnapshotIn<typeof PerformanceModel>>
		) {
			const performance = yield PerformanceService.updateWithIncludes(
				self.id,
				performanceData
			);

			if (performance) {
				performance.tickets = self.tickets;
				applySnapshot(self, performance);
				alertUtil.success("De voorstelling is bijgewerkt");
			}
		}),
		createGroup: flow(function* (groupData: {
			amount: number;
			inviter: string;
			performanceId: number;
		}) {
			const result = yield TicketService.createGroup(groupData);
			return result;
		}),
		deleteTicket: flow(function* (id: number) {
			const ticket = self.tickets.slice().find((ticket) => ticket.id === id);

			var result;
			if (ticket.isGroup) {
				result = yield TicketService.cancelGroupTicket(id);
				ticket.patch(result);
			} else {
				result = yield TicketService.delete(id);
				self.tickets.remove(ticket);
			}

			return result;
		}),
		deleteTicketGroup: flow(function* (pincode: string) {
			const tickets = self.tickets
				.slice()
				.filter((ticket) => ticket.pincode === pincode);

			const result = yield TicketService.deleteGroupTicket(pincode);

			if (result) {
				for (var ticket of tickets) {
					self.tickets.remove(ticket);
				}
			}

			return result;
		}),
	}))
	.views((self) => ({
		get Possession() {
			return `${self.maxVisitors - self.available} / ${self.maxVisitors}`;
		},
		get IndividualTickets() {
			return self.tickets
				.slice()
				.filter((ticket) => ticket.type === "INDIVIDUAL")
				.map((ticket) => ticket.TableData);
		},
		get PartnerTickets() {
			return self.tickets
				.slice()
				.filter((ticket) => ticket.type === "PARTNER")
				.map((ticket) => ticket.TableData);
		},
		get GroupTickets() {
			const tickets = self.tickets.slice().filter((item) => {
				return item.type === "GROUP";
			});

			return tickets.reduce((result, ticket) => {
				const group = ticket.inviterName + "_" + ticket.pincode;

				if (!result[group]) result[group] = [];

				result[group].push(ticket.TableData);
				return result;
			}, []);
		},
		get Available() {
			return self.deletedAt ? 0 : self.maxVisitors - self.tickets.length;
		},
		get Date() {
			return moment(self.date).format("DD-MM-yyyy");
		},
		get Scanned() {
			return self.tickets.slice().filter((ticket) => ticket.IsScanned).length;
		},
		get ScannableTickets() {
			return self.tickets.slice().filter((ticket) => !!ticket.user).length;
		},
		get Started() {
			return self.tickets.slice().filter((ticket) => !!ticket.startedAt).length;
		},
		get Ended() {
			return self.tickets.slice().filter((ticket) => !!ticket.endedAt).length;
		},
		get SubmittedHelpquestions() {
			return self.tickets
				.slice()
				.filter((ticket) => ticket.hasSubmittedHelpQuestions).length;
		},
	}));

export interface PerformanceInstance
	extends Instance<typeof PerformanceModel> {}

export interface PerformanceSnapshot
	extends SnapshotIn<typeof PerformanceModel> {}

// @ts-ignore
export default PerformanceModel;
