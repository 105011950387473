import React from "react";
import { Form } from "react-bootstrap";

const TextInput = ({
  label,
  id,
  placeholder,
  required,
  defaultValue,
  rows,
  disabled,
  caption,
  onInputChanged,
  children,
  className,
}: {
  label: string;
  id: string;
  required?: boolean;
  placeholder?: string;
  defaultValue?: string;
  rows?: number;
  disabled?: boolean;
  caption?: string;
  onInputChanged?: (value) => Promise<void>;
  children?: any;
  className?: string;
}) => {
  return (
    <Form.Group className={className}>
      <label htmlFor={id}>
        {label} {required && "*"}
      </label>
      {!rows ? (
        <>
          <Form.Control
            type="text"
            id={id}
            placeholder={placeholder}
            defaultValue={defaultValue}
            required={required}
            disabled={disabled}
            onChange={(event) => {
              if (onInputChanged) onInputChanged(event.target.value);
            }}
          />
          {caption && (
            <small>
              <strong>{caption}</strong>
            </small>
          )}
        </>
      ) : (
        <Form.Control
          as="textarea"
          rows={rows}
          type="text"
          id={id}
          defaultValue={defaultValue}
          required={required}
          onChange={(event) => {
            if (onInputChanged) onInputChanged(event.target.value);
          }}
        />
      )}
      {children}
    </Form.Group>
  );
};

export default TextInput;
