import CrudService from "./CrudService";
import { HelpQuestionSnapshot } from "stores/HelpQuestionStore/HelpQuestionModel";
import ApiService from "./ApiService";

class HelpQuestionService extends CrudService<HelpQuestionSnapshot> {
  moveUp = async (id: number) => {
    const response = await ApiService.put(`${this.basename}/${id}/up`);
    return response.data;
  };

  moveDown = async (id: number) => {
    const response = await ApiService.put(`${this.basename}/${id}/down`);
    return response.data;
  };

  moveToTop = async (id: number) => {
    const response = await ApiService.put(`${this.basename}/${id}/top`);
    return response.data;
  };

  moveToBottom = async (id: number) => {
    const response = await ApiService.put(`${this.basename}/${id}/bottom`);
    return response.data;
  };
}

export default new HelpQuestionService("/helpquestions");
