import { types, Instance, flow, destroy } from "mobx-state-tree";
import makeInspectable from "mobx-devtools-mst";

import { UserService } from "services";

import UserStore from "./UserStore/UserStore";
import LocationStore from "./LocationStore/LocationStore";
import EventStore from "./EventStore/EventStore";
import PerformanceStore from "./PerformanceStore/PerformanceStore";
import EmployeeStore from "./EmployeeStore/EmployeeStore";
import RoomStore from "./RoomStore/RoomStore";
import PersonaStore from "./PersonaStore/PersonaStore";
import FaqStore from "./FaqStore/FaqStore";
import StatementStore from "./StatementStore/StatementStore";
import AttendeeStore from "./AttendeeStore/AttendeeStore";
import FragmentStore from "./FragmentStore/FragmentStore";
import PostStore from "./PostStore/PostStore";
import HelpQuestionStore from "./HelpQuestionStore/HelpQuestionStore";
import HelpQuestionAnswerStore from "./HelpQuestionAnswerStore/HelpQuestionAnswerStore";
import RequestFiltersModel from "./Models/RequestFilters";
import EventModel from "./EventStore/EventModel";
import alertUtil from "util/alertUtil";
import ApiService from "services/ApiService";
import MeModel from "./UserStore/MeModel";

const RootStoreModel = types
  .model("RootStoreModel", {
    User: types.maybeNull(MeModel),
    currentEvent: types.maybeNull(types.reference(EventModel)),
    UserStore,
    LocationStore,
    EventStore,
    PerformanceStore,
    EmployeeStore,
    RoomStore,
    PersonaStore,
    FaqStore,
    AttendeeStore,
    FragmentStore,
    PostStore,
    StatementStore,
    HelpQuestionStore,
    HelpQuestionAnswerStore,
    hasLoaded: types.maybe(types.boolean),
    isAuthenticated: types.maybe(types.boolean),
  })
  .actions((self) => ({
    setCurrentEvent: flow(function* (id: number) {
      ApiService.setEventId(id);

      self.LocationStore.reset();
      self.EmployeeStore.reset();
      self.FaqStore.reset();
      self.FragmentStore.reset();
      self.PostStore.reset();

      self.currentEvent = id as any;
      localStorage.setItem("currentEvent", id.toString());

      yield Promise.all([
        self.RoomStore.load(),
        self.PersonaStore.load(),
        self.HelpQuestionStore.load(),
      ]);
    }),
  }))
  .actions((self) => ({
    reset: () => {
      self.User && destroy(self.User);
      self.LocationStore.reset();
      self.EventStore.reset();
      self.PerformanceStore.reset();
      self.EmployeeStore.reset();
      self.RoomStore.reset();
      self.PersonaStore.reset();
      self.FaqStore.reset();
      self.FragmentStore.reset();
      self.PostStore.reset();
      self.StatementStore.reset();
      self.HelpQuestionStore.reset();
      self.HelpQuestionAnswerStore.reset();
      self.isAuthenticated = false;
      self.hasLoaded = false;
      self.currentEvent = null;
    },

    setIsAuthenticated: (isAuthenticated: boolean) => {
      self.isAuthenticated = isAuthenticated;
    },

    setHasLoaded: (hasLoaded: boolean) => {
      self.hasLoaded = hasLoaded;
    },
  }))
  .actions((self) => ({
    load: flow(function* () {
      const user = yield UserService.me();

      if (!user) {
        self.reset();
        ApiService.clearLocalStorage();
        return;
      }

      if (user.role === "USER") {
        alertUtil.error(
          "U bent niet bevoegd om gebruik te maken van dit dashboard"
        );

        self.isAuthenticated = false;
        ApiService.clearLocalStorage();
      } else {
        self.User = user;

        yield self.EventStore.load();
        var eventId = Number(localStorage.getItem("currentEvent"));

        if (!eventId) eventId = self.EventStore.events[0].id;

        yield self.setCurrentEvent(eventId);
        self.hasLoaded = true;
      }
    }),
  }));

const RootStore = RootStoreModel.create({
  UserStore: { filters: RequestFiltersModel.create() },
  LocationStore: { filters: RequestFiltersModel.create() },
  PerformanceStore: { filters: RequestFiltersModel.create() },
  EmployeeStore: { filters: RequestFiltersModel.create() },
  FaqStore: { filters: RequestFiltersModel.create() },
  FragmentStore: { filters: RequestFiltersModel.create() },
  PostStore: { filters: RequestFiltersModel.create() },
  HelpQuestionAnswerStore: { filters: RequestFiltersModel.create() },
  HelpQuestionStore: { filters: RequestFiltersModel.create() },
  EventStore: {},
  RoomStore: {},
  PersonaStore: {},
  StatementStore: {},
  AttendeeStore: {},
});

export default makeInspectable(RootStore);

export interface RootStoreInstance extends Instance<typeof RootStoreModel> {}
