import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import Loader from "components/Loader";
import { RoomInstance } from "stores/RoomStore/RoomModel";
import { TextInput, FileInput, SearchSelect } from "./FormElements";
import { RootStore } from "stores";
import { observer } from "mobx-react-lite";

const RoomForm = ({
  onSubmitCallback,
  room,
}: {
  onSubmitCallback: (
    roomData: Partial<RoomInstance>,
    files: any
  ) => Promise<void>;
  room?: RoomInstance;
}) => {
  const onSubmit = async (formEvent) => {
    setLoading(true);

    formEvent.preventDefault();

    const roomData: Partial<RoomInstance> = {
      name: formEvent.target[0].value,
      description: formEvent.target[1].value,
      color: formEvent.target[2].value,
      eventId: RootStore.currentEvent.id,
    };

    if (employeeId) {
      roomData.employeeId = employeeId;
    }

    if (room) {
      roomData.id = room.id;
    }

    const files = { iconFile, imageFile, videoFile };

    await onSubmitCallback(roomData, files);

    setLoading(false);
  };

  const [loading, setLoading] = useState(false);
  const [iconFile, setIconFile] = useState<any>();
  const [imageFile, setImageFile] = useState<any>();
  const [videoFile, setVideoFile] = useState<any>();
  const [employeeId, setEmployeeId] = useState<any>(room?.employeeId);

  return (
    <Form onSubmit={onSubmit}>
      <TextInput
        label="Naam"
        id="name"
        placeholder="Naam"
        required={true}
        defaultValue={room?.name}
      ></TextInput>

      <TextInput
        label="Beschrijving"
        id="description"
        placeholder="Beschrijving"
        required={true}
        defaultValue={room?.description}
        rows={5}
      ></TextInput>

      <TextInput
        label="Kleur"
        id="color"
        placeholder="#12AB3F"
        required={true}
        defaultValue={room?.color}
      ></TextInput>

      <FileInput
        required={!room ? true : false}
        label="Icoon"
        id="iconFile"
        type="image"
        defaultValue={room?.icon}
        stateFile={iconFile}
        onFileChange={(event) => setIconFile(event.target.files[0])}
      ></FileInput>

      <FileInput
        required={!room ? true : false}
        label="Afbeelding"
        id="imageFile"
        type="image"
        defaultValue={room?.image}
        stateFile={imageFile}
        onFileChange={(event) => setImageFile(event.target.files[0])}
      ></FileInput>

      <FileInput
        required={!room ? true : false}
        label="Video"
        id="videoFile"
        type="video"
        defaultValue={room?.video}
        stateFile={videoFile}
        onFileChange={(event) => setVideoFile(event.target.files[0])}
      ></FileInput>

      <SearchSelect
        label="Medewerker"
        id="employee"
        key="employee"
        onSearch={async (searchPhrase: string) => {
          RootStore.EmployeeStore.filters.setSearchPhrase(searchPhrase, true);

          await RootStore.EmployeeStore.load();

          return RootStore.EmployeeStore.employees.map((employee) => ({
            value: employee.id,
            label: employee.name,
          }));
        }}
        required={false}
        onChange={(value) => setEmployeeId(Number(value))}
      >
        {room && <label className="mt-2">{room?.employeeName}</label>}
      </SearchSelect>

      <Button type="submit" className="btn btn-primary mr-2 position-relative">
        <Loader position="right" display={loading}></Loader>
        {room ? "Opslaan" : "Toevoegen"}
      </Button>
    </Form>
  );
};

export default observer(RoomForm);
