import React, { useState } from "react";
import { RootStore } from "stores";
import { observer } from "mobx-react-lite";
import { OverviewPage } from "pages/Base";
import { StatementForm } from "components/forms";
import { StatementSnapshot } from "stores/StatementStore/StatementModel";

const StatementsOverviewPage = () => {
  const [showModal, setShowModal] = useState(false);

  const columns = [
    {
      dataField: "text",
      text: "Stelling",
    },
  ];

  const onCreateSubmit = async (values: StatementSnapshot) => {
    await RootStore.StatementStore.create(values);

    setShowModal(false);
  };

  const form = (
    <StatementForm onSubmitCallback={onCreateSubmit}></StatementForm>
  );

  return (
    <>
      <OverviewPage
        name="Stellingen"
        singular="Stelling"
        path="stellingen"
        onLoad={() => RootStore.StatementStore.load()}
        columns={columns}
        form={form}
        showModal={showModal}
        items={RootStore.StatementStore.statements}
      ></OverviewPage>
    </>
  );
};

export default observer(StatementsOverviewPage);
