import React, { useState, useEffect, useCallback } from "react";
import { observer } from "mobx-react-lite";
import { Loader } from "components";
import { AuthService } from "services";

const ActivateEmail = ({
  isUpdate = false,
  match,
}: {
  isUpdate?: boolean;
  match?: { params: any };
}) => {
  const activate = useCallback(
    async (token: string) => {
      const response = await AuthService.activateEmail(token, isUpdate);

      setSuccess(response);
      setLoading(false);
      return response;
    },
    [isUpdate]
  );

  useEffect(() => {
    activate(match.params.token);
  }, [activate, match.params.token]);

  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);

  return (
    <div>
      <div className="d-flex align-items-center auth px-0">
        <div className="row w-100 mx-0">
          <div className="col-xl-4 col-md-8 mx-auto">
            <div className="auth-form-light text-left py-5 px-4 px-sm-5">
              <div className="brand-logo">
                <img
                  src={require("../../assets/images/logo-hvs.png")}
                  alt="logo"
                />
              </div>
              <div className="position-relative">
                {loading ? (
                  <Loader
                    display={loading}
                    position={"left"}
                    style={{ left: 5 }}
                    size={50}
                  ></Loader>
                ) : (
                  <>
                    Je {isUpdate ? "nieuwe e-mailadres" : "account"}{" "}
                    {!success ? "kon niet worden " : "is "} geactiveerd
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(ActivateEmail);
