import CrudService from "./CrudService";
import PaginatedResponse from "stores/Models/PaginatedResponse";
import { RoomSnapshot } from "stores/RoomStore/RoomModel";
import { EmployeeSnapshot } from "stores/EmployeeStore/EmployeeModel";

interface RoomModel extends RoomSnapshot {
  employee?: EmployeeSnapshot;
}

class RoomService extends CrudService<RoomModel> {
  listWithIncludes = async (): Promise<PaginatedResponse<RoomSnapshot>> => {
    const response = await this.list();
    response.results = response.results.map((room) => {
      return this.transform(room);
    });

    return response;
  };

  singleWithIncludes = async (id: number): Promise<RoomSnapshot> => {
    var room = await this.single(id);

    return this.transform(room);
  };

  createWithIncludes = async (
    roomData: Partial<RoomSnapshot>,
    files: any
  ): Promise<RoomSnapshot> => {
    var room = await this.create(roomData, files);

    return this.transform(room);
  };

  updateWithIncludes = async (
    id: number,
    roomData: Partial<RoomSnapshot>,
    files: any
  ): Promise<RoomSnapshot> => {
    var room = await this.update(id, roomData, files);
    return this.transform(room);
  };

  transform = (room: RoomModel): RoomSnapshot => {
    if (room) {
      room.event = room.eventId;
      room.employeeName = room.employee?.name;
    }
    return room;
  };
}

export default new RoomService("/rooms");
