import { types, SnapshotIn, Instance, flow, destroy } from "mobx-state-tree";
import PersonaModel, { PersonaSnapshot } from "./PersonaModel";
import { PersonaService } from "services";
import alertUtil from "util/alertUtil";

const PersonaStoreModel = types
  .model("PersonaStoreModel", {
    personas: types.array(PersonaModel),
    current: types.maybeNull(types.reference(PersonaModel)),
  })
  .actions((self) => ({
    load: flow(function* () {
      const personas = yield PersonaService.listWithIncludes();
      self.personas = personas.results;
    }),
    get: flow(function* (id: number) {
      const persona = yield PersonaService.singleWithIncludes(id);
      if (persona) self.personas.push(persona);
      return persona;
    }),
    reset: () => {
      destroy(self.personas);
    },
    create: flow(function* (personaData: Partial<PersonaSnapshot>, files) {
      const persona = yield PersonaService.createWithIncludes(personaData, files);

      if (persona) {
        self.personas.push(persona);
        alertUtil.success("Het personage is aangemaakt");
      }
    }),
  }))
  .actions((self) => ({
    setCurrent: flow(function* (id: number) {
      if (!self.personas.some((persona) => persona.id === id)) {
        const persona = yield self.get(id);
        if (!persona) return false;
      }

      self.current = id as any;
      return true;
    }),

    unsetCurrent: () => {
      self.current = null;
    },
  }));

export interface PersonaStoreSnapshot
  extends SnapshotIn<typeof PersonaStoreModel> {}

export interface PersonaStoreInstance
  extends Instance<typeof PersonaStoreModel> {}

export default PersonaStoreModel;
