import React, { useState } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select-v2";

const SearchSelect = ({
  label,
  id,
  required,
  onSearch,
  customOptions,
  defaultValue,
  onChange,
  children,
  disabled,
}: {
  label: string;
  id: string;
  required: boolean;
  onChange: (value: string) => void;
  customOptions?: { label: string; value: string }[];
  onSearch?: (
    searchPhrase: string
  ) => Promise<{ label: string; value: string }[]>;
  defaultValue?: { label: string; value: string };
  children?: any;
  disabled?: boolean;
}) => {
  const [options, setOptions] = useState<{ label: string; value: string }[]>(
    customOptions
  );

  let timeout: NodeJS.Timeout;

  const onInputChange = (inputValue: string) => {
    if (onSearch) {
      clearTimeout(timeout);

      timeout = setTimeout(async () => {
        const result = await onSearch(inputValue);
        setOptions(result);
      }, 250);
    }
  };

  return (
    <Form.Group>
      <label htmlFor={id}>
        {label} {required && "*"}
      </label>
      {!disabled ? (
        <Select
          options={options}
          defaultValue={defaultValue}
          onInputChange={onInputChange}
          onChange={(field) => onChange(field.value)}
        ></Select>
      ) : (
        // Important to maintain the same amount of form controls, even when disabled
        <Form.Control style={{ display: "none" }}></Form.Control>
      )}
      <div>
        <i>{children}</i>
      </div>
    </Form.Group>
  );
};

export default SearchSelect;
