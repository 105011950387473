import React from "react";
import { RootStore } from "stores";
import { PersonaSnapshot } from "stores/PersonaStore/PersonaModel";
import DetailPage from "pages/Base/DetailPage";
import { observer } from "mobx-react-lite";
import { PersonaForm } from "components/forms";

const PersonaDetailPage = ({ match }: { match: { params: any } }) => {
  const onEditSubmit = async (personaData: Partial<PersonaSnapshot>, files) => {
    await RootStore.PersonaStore.current?.update(personaData, files);
  };

  return (
    <DetailPage
      current={RootStore.PersonaStore.current}
      store={RootStore.PersonaStore}
      title={RootStore.PersonaStore.current?.name}
      form={
        <PersonaForm
          onSubmitCallback={onEditSubmit}
          persona={RootStore.PersonaStore.current}
        ></PersonaForm>
      }
      match={match}
    ></DetailPage>
  );
};

export default observer(PersonaDetailPage);
