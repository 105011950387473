import React from "react";
import { Form } from "react-bootstrap";
import DatePicker from "react-datepicker";

const DateInput = ({
  label,
  id,
  required,
  onChange,
  defaultValue,
  highlightedDates
}: {
  id: string;
  required: boolean;
  onChange: (date: Date) => void;
  label?: string;
  defaultValue?: Date;
  highlightedDates?: Date[];
}) => {
  return (
    <Form.Group>
      {label && <label htmlFor={id}>{label}</label>}
      <div className="col-md-12">
        <DatePicker
          className="form-control"
          selected={defaultValue}
          onChange={(date: Date) => onChange(date)}
          dateFormat="dd-MM-yyyy"
          required={required}
          highlightDates={highlightedDates}
        />
      </div>
    </Form.Group>
  );
};

export default DateInput;
