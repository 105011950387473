import React from "react";
import { Form } from "react-bootstrap";

const NumberInput = ({
  label,
  id,
  placeholder,
  required,
  defaultValue,
  min = 0,
  max = Math.max(),
}: {
  label: string;
  id: string;
  required: boolean;
  placeholder?: string;
  defaultValue?: number;
  min?: number;
  max?: number;
}) => {
  return (
    <Form.Group>
      <label htmlFor={id}>
        {label} {required && "*"}
      </label>
      <Form.Control
        type="number"
        id={id}
        placeholder={placeholder}
        defaultValue={defaultValue}
        required={required}
        min={min}
        max={max}
      />
    </Form.Group>
  );
};

export default NumberInput;
