import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { TextInput, NumberInput } from "./FormElements";
import { PerformanceInstance } from "stores/PerformanceStore/PerformanceModel";
import Loader from "components/Loader";

const TicketGroupForm = ({
  performance,
  onSubmitCallback,
}: {
  performance: PerformanceInstance;
  onSubmitCallback?: (groupData: {
    amount: number;
    inviter: string;
    performanceId: number;
  }) => Promise<void>;
}) => {
  const [loading, setLoading] = useState(false);

  const onSubmit = async (formEvent) => {
    formEvent.preventDefault();

    setLoading(true);

    const groupData = {
      amount: formEvent.target[0].value,
      inviter: formEvent.target[1].value,
      performanceId: performance.id,
    };

    await onSubmitCallback(groupData);

    setLoading(false);
  };

  return (
    <Form onSubmit={onSubmit}>
      <p>Er zijn nog {performance.Available} plekken over.</p>

      <NumberInput
        label="Aantal"
        id="amount"
        required={true}
        min={1}
        defaultValue={1}
        max={performance.Available}
      />

      <TextInput
        label="Naam organisatie/uitnodiger"
        id="inviter"
        required={true}
        placeholder="TheaterMakers"
      />

      <Button type="submit" className="btn btn-primary mr-2 position-relative">
        <Loader position="right" display={loading}></Loader>
        Toevoegen
      </Button>
    </Form>
  );
};

export default TicketGroupForm;
