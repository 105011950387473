import React from "react";
import { Form, Button } from "react-bootstrap";
import { TicketInstance } from "stores/UserStore/TicketModel";
import dateUtil from "util/dateUtil";
import { TextInput } from "./FormElements";

const TicketForm = ({
  ticket,
  onRemoveClick,
}: {
  ticket: TicketInstance;
  onRemoveClick: (id: number) => void;
}) => {
  return (
    <Form>
      {ticket.user && (
        <TextInput
          id="user"
          label="Eigenaar"
          defaultValue={ticket.user?.fullName}
          disabled={true}
          caption={ticket.user?.email}
        ></TextInput>
      )}
      <TextInput
        id="eventCode"
        label="Ticketcode"
        defaultValue={ticket.eventCode}
        disabled={true}
      ></TextInput>
      {ticket.type !== "INDIVIDUAL" && (
        <>
          <TextInput
            id="pincode"
            label="Pincode"
            defaultValue={ticket.pincode}
            disabled={true}
          ></TextInput>

          <TextInput
            id="inviterName"
            label="Uitgenodigd door"
            defaultValue={ticket.inviterName}
            disabled={true}
          ></TextInput>
        </>
      )}

      {/* 
      2021-10-21 Commented because dietary requirements functionality is temporarily disabled
      {ticket.HasDietaryRequirements && (
        <Form.Group>
          <label>
            Dieetwensen:
            <ul>
              {ticket.dietaryRequirement.ViewData.map((requirement) => {
                return <li>{requirement}</li>;
              })}
            </ul>
          </label>
        </Form.Group>
      )} */}

      <TextInput
        id="scannedAt"
        label="Gescand op"
        defaultValue={dateUtil.UtcStringToFormattedString(ticket.scannedAt)}
        disabled={true}
      ></TextInput>
      {ticket.startedAt && (
        <TextInput
          id="startedAt"
          label="Voorstelling gestart op"
          defaultValue={dateUtil.UtcStringToFormattedString(ticket.startedAt)}
          disabled={true}
        ></TextInput>
      )}
      {ticket.endedAt && (
        <>
          <TextInput
            id="startedAt"
            label="Voorstelling geëindigd op"
            defaultValue={dateUtil.UtcStringToFormattedString(ticket.startedAt)}
            disabled={true}
          ></TextInput>

          <Form.Group>
            <label>Hulpvragen ingediend</label>
            <p>{ticket.hasSubmittedHelpQuestions ? "Ja" : "Nee"}</p>
          </Form.Group>
        </>
      )}
      {(!ticket.isGroup || !!ticket.user) &&
        <Button
          variant="danger"
          onClick={async () => await onRemoveClick(ticket.id)}
        >
          {ticket.isGroup ? "Ticket leegmaken" : "Ticket verwijderen"}
        </Button>
      }
    </Form>
  );
};

export default TicketForm;
