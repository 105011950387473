import PaginatedResponse from "stores/Models/PaginatedResponse";
import { RequestFiltersSnapshot } from "stores/Models/RequestFilters";
import ApiService from "./ApiService";

class CrudService<T> {
  basename: string;

  constructor(basename: string) {
    this.basename = basename;
  }

  public list = async (
    filters?: RequestFiltersSnapshot,
    customQuery?: any
  ): Promise<PaginatedResponse<T>> => {
    const query = {
      ...filters,
      ...customQuery,
    };

    const response = await ApiService.get(
      this.basename,
      filters || customQuery ? query : undefined
    );

    return response.data;
  };

  public single = async (id: number): Promise<T> => {
    const response = await ApiService.get(`${this.basename}/${id}`);
    return response.data;
  };

  public create = async (model: Partial<T>, files?: any): Promise<T> => {
    var body;

    if (files) {
      body = new FormData();

      for (const key in model) {
        if (model[key]) body.set(key, model[key]);
      }

      for (const key in files) {
        if (files[key]) body.append(key, files[key]);
      }
    } else {
      body = model;
    }

    const response = await ApiService.post(this.basename, body);
    return response.data;
  };

  public update = async (
    id: number,
    model: Partial<T>,
    files?: any
  ): Promise<T> => {
    var body;

    if (files) {
      body = new FormData();

      for (const key in model) {
        body.set(key, model[key]);
      }

      for (const key in files) {
        if (files[key]) body.append(key, files[key]);
      }
    } else {
      body = model;
    }

    const response = await ApiService.put(`${this.basename}/${id}`, body);
    return response.data;
  };

  public delete = async (id: number): Promise<boolean> => {
    const response = await ApiService.delete(`${this.basename}/${id}`);
    return response.status === 200;
  };
}

export default CrudService;
