import React, { useCallback, useEffect, useState } from "react";
import { RootStore } from "stores";
import { observer } from "mobx-react-lite";
import { OverviewPage } from "pages/Base";
import { CheckInput, DateInput } from "components/forms/FormElements";
import { Button } from "react-bootstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import { PerformanceService } from "services";
import { saveAs } from "file-saver";
import storageUtil from "util/storageUtil";
import queryString from "query-string";

const HelpQuestionAnswersOverviewPage = () => {
  const location = useLocation();
  const history = useHistory();

  const baseHelpQuestionColumns = [
    {
      dataField: "helpQuestion",
      text: "Vraag",
      formatter: function Formatter(_cell, row) {
        if (row.helpQuestionTitle) {
          return row.helpQuestionTitle;
        }
      },
    },
    {
      dataField: "answer",
      text: "Antwoord",
      formatter: function Formatter(_cell) {
        if (_cell && _cell.length > 0) {
          return _cell;
        }

        return <i>Geen antwoord</i>;
      },
    },
    {
      dataField: "audio",
      text: "Spraakopname",
      formatter: function Formatter(_cell) {
        if (_cell) {
          return (
            <audio controls className="mt-3">
              <source src={storageUtil.getStoragePath(_cell)} />
            </audio>
          );
        }

        return <i>Geen audio</i>;
      },
    },
  ];

  const defaultHelpQuestionColumns = [
    {
      dataField: "userName",
      text: "Ingediend door",
      formatter: function Formatter(_cell, row) {
        return <Link to={`gebruikers/${row.userId}`}>{_cell}</Link>;
      },
    },
    ...baseHelpQuestionColumns,
    {
      dataField: "createdAt",
      text: "Ingediend op",
      formatter: function Formatter(_cell) {
        return moment(_cell).format("DD-MM-YYYY HH:mm");
      },
    },
  ];

  const performanceHelpQuestionColumns = [...baseHelpQuestionColumns];
  const [selectedDate, setSelectedDate] = useState<Date>();
  const [performanceDates, setPerformanceDates] = useState<Date[]>();

  const [includeArchive, setIncludeArchive] = useState<boolean>();

  const onCheckIncludeArchive = async (active: boolean) => {
    setIncludeArchive(active);
  };

  const customFilters = (
    <CheckInput
      id="includeArchive"
      label="Gearchiveerde hulpvragen tonen"
      defaultValue={false}
      key="includeArchive"
      onChange={onCheckIncludeArchive}
    ></CheckInput>
  );

  const getPerformanceDates = useCallback(async () => {
    const dates = await PerformanceService.getDates(includeArchive);
    setPerformanceDates(dates);
  }, [includeArchive]);

  useEffect(() => {
    getPerformanceDates();

    const query = queryString.parse(location.search);

    if (query.date) {
      const date = moment(query.date).toDate();
      setSelectedDate(date);
    }
  }, [getPerformanceDates, location.search, includeArchive]);

  useEffect(() => {
    if (selectedDate) {
      history.push({
        search: `?date=${moment(selectedDate).format("yyyy-MM-DD")}`,
      });

      RootStore.HelpQuestionAnswerStore.load(selectedDate, includeArchive);
    } else {
      history.replace({
        search: "",
      });
    }
  }, [selectedDate, includeArchive, history]);

  const exportHelpQuestions = async () => {
    if (RootStore.HelpQuestionAnswerStore.helpQuestionAnswers.length > 0) {
      const _export = await PerformanceService.exportHelpQuestions(
        RootStore.HelpQuestionAnswerStore.helpQuestionAnswers[0].performanceId
      );

      if (_export)
        saveAs(
          new Blob([_export], { type: "text/csv" }),
          `Hulpvragen HvS ${moment(selectedDate).format("DD-MM-yyyy")}.csv`
        );
    }
  };

  return (
    <>
      <div className="row mb-4">
        <div className="col-md-12">
          <label>Voorstelling datum</label>
          <Button
            onClick={() => {
              setSelectedDate(null);
            }}
            variant="link"
          >
            Filter wissen
          </Button>
          {selectedDate && (
            <Button onClick={() => exportHelpQuestions()}>Exporteren</Button>
          )}
          <DateInput
            id="date"
            required={false}
            onChange={setSelectedDate}
            defaultValue={selectedDate}
            highlightedDates={performanceDates}
          />

          {customFilters}
        </div>
      </div>

      {selectedDate &&
        RootStore.HelpQuestionAnswerStore.helpQuestionAnswers.length > 0 && (
          <div className="row mb-4">
            <div className="col-md-12">
              <h3 className="page-title">
                Voorstelling:{" "}
                <Link
                  to={`voorstellingen/${RootStore.HelpQuestionAnswerStore.helpQuestionAnswers[0].performanceId}`}
                >
                  {moment(selectedDate).format("DD-MM-YYYY")}
                </Link>
              </h3>
            </div>
          </div>
        )}

      {!selectedDate && (
        <div className="row mb-5">
          <div className="col-lg-12">
            <OverviewPage
              name="Algemene hulpvragen"
              singular="Hulpvraag antwoord"
              columns={defaultHelpQuestionColumns}
              onLoad={() => RootStore.HelpQuestionAnswerStore.load(null, includeArchive)}
              items={RootStore.HelpQuestionAnswerStore.helpQuestionAnswers}
              filters={RootStore.HelpQuestionAnswerStore.filters}
            ></OverviewPage>
          </div>
        </div>
      )}

      {selectedDate ? (
        RootStore.HelpQuestionAnswerStore.helpQuestionAnswers.length > 0 ? (
          Object.keys(RootStore.HelpQuestionAnswerStore.splitPerUser).map(
            (ticketId) => {
              const answers = RootStore.HelpQuestionAnswerStore.splitPerUser;
              const ticketAnswers = answers[ticketId];
              const first = ticketAnswers[0];

              return (
                <div className="row mb-5">
                  <div className="col-lg-12">
                    <OverviewPage
                      name={
                        <Link to={`gebruikers/${first.userId}`}>
                          {first.userName}
                        </Link>
                      }
                      singular="Hulpvraag antwoord"
                      columns={performanceHelpQuestionColumns}
                      items={ticketAnswers}
                    ></OverviewPage>
                  </div>
                </div>
              );
            }
          )
        ) : (
          <i>
            Geen voorstelling op deze datum of geen hulpvragen voor deze
            voorstelling
          </i>
        )
      ) : (
        <></>
      )}
    </>
  );
};

export default observer(HelpQuestionAnswersOverviewPage);
