import React from "react";
import { Form } from "react-bootstrap";
import storageUtil from "util/storageUtil";

const FileInput = ({
  label,
  id,
  type,
  onFileChange,
  stateFile,
  defaultValue,
  required,
  value,
}: {
  label: string;
  id: string;
  type: "audio" | "image" | "video";
  required: boolean;
  onFileChange: (event: any) => void;
  stateFile: any;
  defaultValue?: any;
  value?: any;
}) => {
  const extensions = {
    audio: ".mp3,.ogg,.wav",
    image: ".png,.jpg,.jpeg",
    video: ".mp4",
  };

  const src = stateFile
    ? URL.createObjectURL(stateFile)
    : defaultValue
    ? storageUtil.getStoragePath(defaultValue)
    : null;

  const getPreview = () => {
    if (src) {
      if (type === "audio") {
        return (
          <audio controls className="mt-3">
            <source src={src} />
          </audio>
        );
      } else if (type === "video") {
        return (
          <video controls className="mt-3" width="300px">
            <source src={src} />
          </video>
        );
      } else {
        return (
          <img alt="Input preview" src={src} className="img-lg mt-3"></img>
        );
      }
    }
  };

  return (
    <Form.Group>
      <label>
        {label} {required && "*"}
      </label>
      <div className="custom-file">
        <Form.Control
          type="file"
          className="form-control visibility-hidden"
          id={id}
          onChange={onFileChange}
          accept={extensions[type]}
          required={required}
        />
        <label className="custom-file-label" htmlFor={id}>
          {stateFile
            ? stateFile.name
            : defaultValue
            ? storageUtil.getFileName(defaultValue)
            : "Selecteer bestand"}
        </label>
      </div>

      {getPreview()}
    </Form.Group>
  );
};

export default FileInput;
