import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import Loader from "components/Loader";
import { PersonaInstance } from "stores/PersonaStore/PersonaModel";
import { TextInput } from "./FormElements";
import { RootStore } from "stores";
import { observer } from "mobx-react-lite";
import { FileInput } from "./FormElements";

const PersonaForm = ({
  onSubmitCallback,
  persona,
}: {
  onSubmitCallback: (
    personaData: Partial<PersonaInstance>,
    files: any
  ) => Promise<void>;
  persona?: PersonaInstance;
}) => {
  const onSubmit = async (formEvent) => {
    setLoading(true);

    formEvent.preventDefault();

    const personaData: Partial<PersonaInstance> = {
      name: formEvent.target[0].value,
      eventId: RootStore.currentEvent.id,
    };

    if (persona) {
      personaData.id = persona.id;
    }

    const files = {
      introFile,
    };

    await onSubmitCallback(personaData, files);

    setLoading(false);
  };

  const [loading, setLoading] = useState(false);
  const [introFile, setAudioFile] = useState<any>(persona?.introFile || null);

  return (
    <Form onSubmit={onSubmit}>
      <TextInput
        label="Naam"
        id="name"
        placeholder="Naam"
        required={true}
        defaultValue={persona?.name}
      ></TextInput>

      <FileInput
        required={!persona}
        label="Intro fragment"
        id="introFile"
        type="audio"
        defaultValue={persona?.introFragment}
        stateFile={introFile}
        onFileChange={(event) => setAudioFile(event.target.files[0])}
      ></FileInput>

      <Button type="submit" className="btn btn-primary mr-2 position-relative">
        <Loader position="right" display={loading}></Loader>
        {persona ? "Opslaan" : "Toevoegen"}
      </Button>
    </Form>
  );
};

export default observer(PersonaForm);
