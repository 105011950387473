import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import Loader from "components/Loader";
import { EmployeeInstance } from "stores/EmployeeStore/EmployeeModel";
import { TextInput, FileInput, RadioButtonGroup } from "./FormElements";
import { RootStore } from "stores";
import { observer } from "mobx-react-lite";

const EmployeeForm = ({
  onSubmitCallback,
  employee,
}: {
  onSubmitCallback: (
    employeeData: Partial<EmployeeInstance>,
    files: any
  ) => Promise<void>;
  employee?: EmployeeInstance;
}) => {
  const onSubmit = async (formEvent) => {
    setLoading(true);

    formEvent.preventDefault();

    const employeeData: Partial<EmployeeInstance> = {
      name: formEvent.target[0].value,
      role: formEvent.target[1].value,
      description: formEvent.target[2].value,
      type: selectedType,
      eventId: RootStore.currentEvent.id,
    };

    if (employee) {
      employeeData.id = employee.id;
    }

    const files = { imageFile, stageImageFile };

    await onSubmitCallback(employeeData, files);

    setLoading(false);
  };

  const [loading, setLoading] = useState(false);
  const [imageFile, setImageFile] = useState<any>();
  const [stageImageFile, setStageImageFile] = useState<any>();
  const [selectedType, setSelectedType] = useState<string>(
    employee?.type ? employee.type : "ACTOR"
  );

  const onTypeChange = (checked: boolean, type: string) => {
    setSelectedType(type);
  };

  return (
    <Form onSubmit={onSubmit}>
      <div className="row">
        <div className="col-md-6">
          <TextInput
            label="Naam"
            id="name"
            placeholder="Naam"
            required={true}
            defaultValue={employee?.name}
          ></TextInput>

          <TextInput
            label="Rol"
            id="role"
            placeholder="Rol"
            required
            defaultValue={employee?.role}
          ></TextInput>

          <TextInput
            label="Beschrijving"
            id="description"
            placeholder="Beschrijving"
            required={false}
            defaultValue={employee?.description}
            rows={5}
          ></TextInput>

          <FileInput
            required={!employee ? true : false}
            label="Afbeelding"
            id="imageFile"
            type="image"
            defaultValue={employee?.image}
            stateFile={imageFile}
            onFileChange={(event) => setImageFile(event.target.files[0])}
          ></FileInput>

          <FileInput
            required={!employee ? true : false}
            label="Afbeelding in rol"
            id="stageImageFile"
            type="image"
            defaultValue={employee?.stageImage}
            stateFile={stageImageFile}
            onFileChange={(event) => setStageImageFile(event.target.files[0])}
          ></FileInput>

          <Button
            type="submit"
            className="btn btn-primary mr-2 position-relative"
          >
            <Loader position="right" display={loading}></Loader>
            {employee ? "Opslaan" : "Toevoegen"}
          </Button>
        </div>

        <div className="col-md-6">
          <RadioButtonGroup
            label="Type"
            id="type"
            options={[
              {
                id: "ACTOR",
                label: "Acteur",
              },
              {
                id: "MUSICIAN",
                label: "Muzikant",
              },
              {
                id: "MANAGEMENT",
                label: "Management",
              },
            ]}
            defaultValue={selectedType}
            onChange={onTypeChange}
          ></RadioButtonGroup>
        </div>
      </div>
    </Form>
  );
};

export default observer(EmployeeForm);
