import React, { useState } from "react";
import { RootStore } from "stores";
import { observer } from "mobx-react-lite";
import { HelpQuestionForm } from "components/forms";
import { HelpQuestionInstance } from "stores/HelpQuestionStore/HelpQuestionModel";
import DetailPage from "pages/Base/DetailPage";
import { HelpQuestionSnapshot } from "stores/HelpQuestionStore/HelpQuestionModel";
import { useHistory } from "react-router";
import { ConfirmModal } from "components";

const HelpQuestionDetailPage = ({ match }: { match: { params } }) => {
  const history = useHistory();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const onEditSubmit = async (
    helpQuestionData: Partial<HelpQuestionSnapshot>
  ) => {
    await RootStore.HelpQuestionStore.current?.update(helpQuestionData);
  };

  const onRemoveSubmit = async () => {
    await RootStore.HelpQuestionStore.delete();
    history.push("/hulpvragen");
  };

  return (
    <>
      <DetailPage<HelpQuestionInstance>
        current={RootStore.HelpQuestionStore.current}
        store={RootStore.HelpQuestionStore}
        title={RootStore.HelpQuestionStore.current?.question}
        form={
          <HelpQuestionForm
            onSubmitCallback={onEditSubmit}
            onRemoveCallback={() => {
              setShowDeleteModal(true);
            }}
            helpQuestion={RootStore.HelpQuestionStore.current}
          ></HelpQuestionForm>
        }
        match={match}
      />

      <ConfirmModal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        onConfirm={onRemoveSubmit}
        modalText="Weet je zeker dat je deze hulpvraag wilt archiveren?"
        modalTitle={"Hulpvraag archiveren"}
      ></ConfirmModal>
    </>
  );
};

export default observer(HelpQuestionDetailPage);
