import React, { useState } from "react";
import { RootStore } from "stores";
import { observer } from "mobx-react-lite";
import { FragmentSnapshot } from "stores/FragmentStore/FragmentModel";
import { FragmentForm } from "components/forms";
import { OverviewPage } from "pages/Base";
import Select from "react-select-v2";

const FragmentsOverviewPage = () => {
  const columns = [
    {
      text: "Fragment #",
      dataField: "index",
    },
    {
      text: "Type",
      dataField: "formattedType",
    },
    {
      dataField: "title",
      text: "Titel",
    },
    {
      dataField: "audio",
      text: "Audio bestand",
      formatter: function Formatter(_cell, row) {
        return <div className="d-flex align-items-center">{_cell}</div>;
      },
    },
  ];

  const roomOptions = RootStore.RoomStore.rooms.map((room) => ({
    label: room.name,
    value: room.id,
  }));

  const personaOptions = RootStore.PersonaStore.personas.map((persona) => ({
    label: persona.name,
    value: persona.id,
  }));

  const [selectedRoom, setSelectedRoom] = useState(roomOptions[0]);
  const [selectedPersona, setSelectedPersona] = useState(personaOptions[0]);
  const [showModal, setShowModal] = useState<boolean>();

  const onCreateSubmit = async (
    fragment: Partial<FragmentSnapshot>,
    files: any
  ) => {
    await RootStore.FragmentStore?.create(fragment, files);
    setShowModal(false);
  };

  const form = (
    <FragmentForm
      room={selectedRoom}
      persona={selectedPersona}
      startRoomId={selectedRoom.value}
      onSubmitCallback={onCreateSubmit}
    ></FragmentForm>
  );

  return (
    <>
      <div className="row mb-4">
        <div className="col-md-4">
          <label className="col-form-label">Startkamer</label>
          <Select
            options={roomOptions}
            value={selectedRoom}
            onChange={(item) => setSelectedRoom(item)}
          ></Select>
        </div>

        <div className="col-md-4">
          <label className="col-form-label">Personage</label>
          <Select
            options={personaOptions}
            value={selectedPersona}
            onChange={(item) => setSelectedPersona(item)}
          ></Select>
        </div>
      </div>

      <OverviewPage
        name="Fragmenten"
        singular="Fragment"
        path="fragmenten"
        onLoad={() =>
          RootStore.FragmentStore.load(
            selectedRoom.value,
            selectedPersona.value
          )
        }
        columns={columns}
        items={RootStore.FragmentStore.fragments.map((item, index) => {
          item.index = index + 1;

          return item;
        })}
        form={form}
        showModal={showModal}
      ></OverviewPage>
    </>
  );
};

export default observer(FragmentsOverviewPage);
