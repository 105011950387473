import { types, Instance, flow, destroy } from "mobx-state-tree";
import LocationModel, { LocationSnapshot } from "./LocationModel";
import { LocationService } from "services";
import RequestFiltersModel from "stores/Models/RequestFilters";
import alertUtil from "util/alertUtil";

const LocationStore = types
  .model({
    locations: types.array(LocationModel),
    current: types.maybeNull(types.reference(LocationModel)),
    filters: RequestFiltersModel,
  })
  .actions((self) => ({
    load: flow(function* () {
      const response = yield LocationService.listWithIncludes(self.filters);
      self.locations = response.results;
      self.filters.update(response);
    }),
    get: flow(function* (id: number) {
      const location = yield LocationService.singleWithIncludes(id);
      if (location) self.locations.push(location);
      return location;
    }),
    reset: () => {
      destroy(self.locations);
      self.filters = RequestFiltersModel.create();
    },
    create: flow(function* (locationData: Partial<LocationSnapshot>) {
      const location = yield LocationService.createWithIncludes(locationData);

      if (location) {
        self.locations.push(location);
        alertUtil.success("De locatie is aangemaakt");
      }
    }),
  }))
  .actions((self) => ({
    setCurrent: flow(function* (id: number) {
      if (!self.locations.some((location) => location.id === id)) {
        const location = yield self.get(id);
        if (!location) return false;
      }

      self.current = id as any;
      return true;
    }),
    unsetCurrent: () => {
      self.current = null;
    },
  }));

export default LocationStore;
export interface LocationStoreInstance extends Instance<typeof LocationStore> {}
