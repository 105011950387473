import ApiService from "services/ApiService";

class storageUtil {
  storageUrl: string | undefined;

  constructor() {
    this.storageUrl = ApiService.getUrl() + "/uploads";
  }

  public getStoragePath = (path: string) => {
    if (path.startsWith("http")) {
      return path;
    }

    return this.storageUrl + "/" + path;
  };

  public getFileName = (path: string) => {
    if (path.startsWith("http")) {
      return path;
    }

    return path.substring(path.lastIndexOf("\\") + 1);
  };
}

export default new storageUtil();
