import { Instance, types, flow, destroy } from "mobx-state-tree";

import EventModel, { EventSnapshot } from "./EventModel";
import { EventService } from "services";
import RootStore from "stores/RootStore";
import alertUtil from "util/alertUtil";

const EventStore = types
  .model("EventStore", {
    events: types.array(EventModel),
  })
  .actions((self) => ({
    load: flow(function* () {
      const filters = {
        page: 1,
        limit: 1001,
        sortBy: "type",
        sortDirection: 1,
      };

      const response = yield EventService.list(filters);
      self.events = response.results as any;
    }),
    reset: () => {
      destroy(self.events);
    },
    create: flow(function* (eventData: Partial<EventSnapshot>, files: any) {
      const event = yield EventService.create(eventData, files);

      if (event) {
        self.events.push(event);
        RootStore.setCurrentEvent(event.id);
        alertUtil.success("Het evenement is aangemaakt");
      }
    }),
  }))
  .views((self) => ({
    get TheaterEvents() {
      return self.events.slice().filter((event) => event.isTheater);
    },
    get ExternalEvents() {
      return self.events.slice().filter((event) => !event.isTheater);
    },
  }));

export interface EventStoreInstance extends Instance<typeof EventStore> {}
export default EventStore;
