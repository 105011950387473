import React, { useState, useEffect, useCallback } from "react";
import { OverviewPage } from "pages";
import { RootStore } from "stores";

const TicketOverview = () => {
  const [items, setItems] = useState<any[]>([]);
  const user = RootStore.UserStore.current;

  const fetchTableData = useCallback(async () => {
    const items: any[] = await Promise.all(
      user.tickets.map((ticket) => ticket.getTableData())
    );

    setItems(items);
  }, [user.tickets]);

  useEffect(() => {
    fetchTableData();
  }, [fetchTableData]);

  const columns = [
    {
      dataField: "eventName",
      text: "Evenement",
    },
    {
      dataField: "locationName",
      text: "Locatie",
    },
    {
      dataField: "datetime",
      text: "Datum en tijd",
    },
    {
      dataField: "isScanned",
      text: "Is gescand",
      formatter: function Formatter(_cell, row) {
        return _cell ? "Ja" : "Nee";
      },
    },
  ];

  return (
    user.tickets && (
      <OverviewPage
        name="Tickets"
        columns={columns}
        items={items}
      ></OverviewPage>
    )
  );
};

export default TicketOverview;
