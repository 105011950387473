import React from "react";
import { Form } from "react-bootstrap";
import DatePicker from "react-datepicker";

const TimeInput = ({
  label,
  id,
  required,
  onChange,
  defaultValue,
}: {
  label: string;
  id: string;
  required: boolean;
  onChange: (date: Date) => void;
  defaultValue?: Date;
}) => {
  return (
    <Form.Group>
      <label htmlFor={id}>{label}</label>
      <div className="col-md-12">
        <DatePicker
          className="form-control"
          selected={defaultValue}
          onChange={(date: Date) => onChange(date)}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={15}
          timeCaption="Time"
          dateFormat="HH:mm"
          timeFormat="HH:mm"
          required={required}
        />
      </div>
    </Form.Group>
  );
};

export default TimeInput;
