import React, { useState } from "react";
import { RootStore } from "stores";
import { observer } from "mobx-react-lite";
import { OverviewPage } from "pages/Base";
import { EmployeeSnapshot } from "stores/EmployeeStore/EmployeeModel";
import { EmployeeForm } from "components/forms";

const EmployeesOverviewPage = () => {
  const columns = [
    {
      dataField: "name",
      text: "Naam",
    },
  ];

  const sortableFields = [
    {
      value: "name",
      label: "Naam",
      defaultAscending: true,
    },
  ];

  const [showModal, setShowModal] = useState<boolean>();

  const onCreateSubmit = async (
    employee: Partial<EmployeeSnapshot>,
    files: any
  ) => {
    await RootStore.EmployeeStore?.create(employee, files);
    setShowModal(false);
  };

  const form = <EmployeeForm onSubmitCallback={onCreateSubmit}></EmployeeForm>;

  return (
    <OverviewPage
      name="Medewerkers"
      singular="Medewerker"
      path="medewerkers"
      onLoad={() => RootStore.EmployeeStore.load()}
      filters={RootStore.EmployeeStore.filters}
      columns={columns}
      items={RootStore.EmployeeStore.employees}
      form={form}
      showModal={showModal}
      sortableFields={sortableFields}
    ></OverviewPage>
  );
};

export default observer(EmployeesOverviewPage);
