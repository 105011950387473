import React, { useState } from "react";
import { RootStore } from "stores";
import { observer } from "mobx-react-lite";
import { OverviewPage } from "pages/Base";
import { HelpQuestionForm } from "components/forms";
import { HelpQuestionSnapshot } from "stores/HelpQuestionStore/HelpQuestionModel";
import { Button, ButtonGroup } from "react-bootstrap";
import { HelpQuestionService } from "services";
import { CheckInput } from "components/forms/FormElements";

const HelpQuestionsOverviewPage = () => {
  const [showModal, setShowModal] = useState(false);

  const [includeArchive, setIncludeArchive] = useState<boolean>();

  const onCheckIncludeArchive = async (active: boolean) => {
    setIncludeArchive(active);
  };

  const customFilters = (
    <CheckInput
      id="includeArchive"
      label="Gearchiveerde hulpvragen tonen"
      defaultValue={false}
      key="includeArchive"
      onChange={onCheckIncludeArchive}
    ></CheckInput>
  );

  const columns = [
    {
      dataField: "question",
      text: "Vraag",
      formatter: function Formmatter(_cell, row) {
        return row.default ? <strong>{_cell}</strong> : <>{_cell}</>;
      },
    },
    {
      dataField: "order",
      text: "Volgorde",
      formatter: function Formatter(_cell, row) {
        return (
          <ButtonGroup>
            <Button
              onClick={() => onMoveToTop(row.id)}
              className="btn btn-outline-primary"
              variant="outline-primary"
            >
              <i className="mdi mdi-chevron-double-up text-primary"></i>
            </Button>
            <Button
              onClick={() => onMoveUp(row.id)}
              className="btn btn-outline-primary"
              variant="outline-primary"
            >
              <i className="mdi mdi-chevron-up text-primary"></i>
            </Button>
            <Button
              onClick={() => onMoveDown(row.id)}
              className="btn btn-outline-primary"
              variant="outline-primary"
            >
              <i className="mdi mdi-chevron-down text-primary"></i>
            </Button>
            <Button
              onClick={() => onMoveToBottom(row.id)}
              className="btn btn-outline-primary"
              variant="outline-primary"
            >
              <i className="mdi mdi-chevron-double-down text-primary"></i>
            </Button>
          </ButtonGroup>
        );
      },
    },
  ];

  const onMoveUp = async (id: number) => {
    await HelpQuestionService.moveUp(id);
    RootStore.HelpQuestionStore.load();
  };

  const onMoveDown = async (id: number) => {
    await HelpQuestionService.moveDown(id);
    RootStore.HelpQuestionStore.load();
  };

  const onMoveToTop = async (id: number) => {
    await HelpQuestionService.moveToTop(id);
    RootStore.HelpQuestionStore.load();
  };

  const onMoveToBottom = async (id: number) => {
    await HelpQuestionService.moveToBottom(id);
    RootStore.HelpQuestionStore.load();
  };

  const onCreateSubmit = async (values: HelpQuestionSnapshot) => {
    await RootStore.HelpQuestionStore.create(values);

    setShowModal(false);
  };

  const form = (
    <HelpQuestionForm onSubmitCallback={onCreateSubmit}></HelpQuestionForm>
  );

  return (
    <>
      <OverviewPage
        name="Hulpvragen"
        singular="Hulpvraag"
        path="hulpvragen"
        onLoad={() => RootStore.HelpQuestionStore.load(includeArchive)}
        columns={columns}
        form={form}
        filters={RootStore.HelpQuestionStore.filters}
        sort={true}
        showModal={showModal}
        items={RootStore.HelpQuestionStore.helpQuestions}
        customFilters={customFilters}
      ></OverviewPage>

      <div className="mt-2">
        <i>Hulpvragen met vetgedrukte teksten zijn de algemene 'hulpvragen'.</i>
      </div>
    </>
  );
};

export default observer(HelpQuestionsOverviewPage);
