import React, { useState } from "react";
import { RootStore } from "stores";
import { PostSnapshot } from "stores/PostStore/PostModel";
import DetailPage from "pages/Base/DetailPage";
import { observer } from "mobx-react-lite";
import { PostForm } from "components/forms";
import { ConfirmModal } from "components";
import { useHistory } from "react-router";

const PostDetailPage = ({ match }: { match: { params: any } }) => {
  const history = useHistory();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const onEditSubmit = async (postData: Partial<PostSnapshot>, files: any) => {
    await RootStore.PostStore.current?.update(postData, files);
  };

  const onRemoveSubmit = async () => {
    await RootStore.PostStore.delete();
    history.push("/berichten");
  };

  return (
    <>
      <DetailPage
        current={RootStore.PostStore.current}
        store={RootStore.PostStore}
        title={RootStore.PostStore.current?.name}
        form={
          <PostForm
            onSubmitCallback={onEditSubmit}
            onRemoveCallback={() => {
              setShowDeleteModal(true);
            }}
            post={RootStore.PostStore.current}
          ></PostForm>
        }
        formColSize={12}
        match={match}
      ></DetailPage>

      <ConfirmModal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        onConfirm={onRemoveSubmit}
        modalText="Weet je zeker dat je dit bericht wilt verwijderen?"
        modalTitle={"Bericht verwijderen"}
      ></ConfirmModal>
    </>
  );
};

export default observer(PostDetailPage);
