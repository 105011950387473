import { types, Instance, flow, destroy } from "mobx-state-tree";
import UserModel, { UserSnapshot } from "./UserModel";
import { UserService } from "services";
import RequestFiltersModel from "stores/Models/RequestFilters";

const UserStore = types
  .model("UserStoreModel", {
    users: types.array(UserModel),
    current: types.maybeNull(types.reference(UserModel)),
    filters: RequestFiltersModel,
  })
  .actions((self) => ({
    load: flow(function* (includeArchive : boolean) {
      const response = yield UserService.listWithChildren(self.filters, includeArchive);
      self.users = response.results;
      self.filters.update(response);
    }),
    get: flow(function* (id: number) {
      const user = yield UserService.singleWithIncludes(id);
      if (user) self.users.push(user);
      return user;
    }),
    reset: () => {
      destroy(self.users);
      self.filters = RequestFiltersModel.create();
    },
    create: flow(function* (userData: Partial<UserSnapshot>) {
      const user = yield UserService.createWithIncludes(userData);
      self.users.push(user);
    })
  }))
  .actions((self) => ({
    setCurrent: flow(function* (id: number) {
      if (!self.users.some((user) => user.id === id)) {
        const user = yield self.get(id);
        if (!user) return false;
      }

      self.current = id as any;
      return true;
    }),
    unsetCurrent: () => {
      self.current = null;
    },
  }));

export default UserStore;
export interface UserStoreInstance extends Instance<typeof UserStore> {}
