import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import { observer } from "mobx-react-lite";
import { RequestFiltersInstance } from "stores/Models/RequestFilters";
import Select from "react-select-v2";

const Filterbar = ({
  filters,
  onFiltersChanged,
  search,
  sort,
  sortableFields,
  customFilters,
}: {
  onFiltersChanged: () => void;
  search: boolean;
  sort: boolean;
  sortableFields?: {
    value: string;
    label: string;
    defaultAscending?: boolean;
  }[];
  filters?: RequestFiltersInstance;
  customFilters?: JSX.Element;
}) => {
  const defaultField = {
    label: "Aanmaakdatum",
    value: "createdAt",
  };

  const directionOptions = [
    {
      value: 1,
      label: "Oplopend",
    },
    {
      value: 2,
      label: "Aflopend",
    },
  ];

  let timeout;

  const setSearchPhrase = (searchPhrase: string) => {
    filters?.setSearchPhrase(searchPhrase);

    clearTimeout(timeout);
    timeout = setTimeout(() => {
      onFiltersChanged();
    }, 250);
  };

  const setSortBy = (value: string) => {
    filters.setSortBy(value);
    onFiltersChanged();
  };

  const setSortDirection = (value: 1 | 2) => {
    filters.setSortDirection(value);
    onFiltersChanged();
  };

  useEffect(() => {
    if (sortableFields) {
      filters.setSortBy(sortableFields[0].value, false);
      filters.setSortDirection(
        sortableFields[0].defaultAscending ? 1 : 2,
        false
      );
    }
  });

  return (
    <div className="col-md-12 mb-3">
      <div className="row">
        <div className="col-md-4">
          {search && (
            <>
              <label className="col-form-label">Zoeken</label>
              <div>
                <Form.Control
                  type="text"
                  id="search"
                  placeholder="Zoekterm invullen.."
                  onChange={(event) => {
                    setSearchPhrase(event.target.value);
                  }}
                />
              </div>
            </>
          )}
        </div>

        {sort && (
          <>
            <div className="col-md-2"></div>
            <div className="col-md-3">
              <label className="col-form-label">Sorteerveld</label>

              <Select
                options={
                  sortableFields
                    ? sortableFields.concat(defaultField)
                    : [defaultField]
                }
                defaultValue={sortableFields ? sortableFields[0] : defaultField}
                onChange={(field) => {
                  setSortBy(field.value);
                }}
              ></Select>
            </div>
            <div className="col-md-3">
              <label className="col-form-label">Sorteervolgorde</label>

              <Select
                options={directionOptions}
                defaultValue={
                  sortableFields &&
                  sortableFields[0] &&
                  sortableFields[0].defaultAscending
                    ? directionOptions[0]
                    : directionOptions[1]
                }
                onChange={(field) => {
                  setSortDirection(field.value);
                }}
              ></Select>
            </div>
          </>
        )}
      </div>
      {customFilters && (
        <div className="row mt-3">
          <div className="col-lg-12">{customFilters}</div>
        </div>
      )}
    </div>
  );
};

export default observer(Filterbar);
