import {
  types,
  Instance,
  SnapshotIn,
  flow,
  applySnapshot,
} from "mobx-state-tree";
// 2021-10-21 Commented because dietary requirements functionality is temporarily disabled
// import DietaryRequirementsModel from "./DietaryRequirementsModel";
import { PerformanceService, LocationService, TicketService } from "services";
import RootStore from "stores/RootStore";
import { EventInstance } from "stores/EventStore/EventModel";
import moment from "moment";
import MeModel from "./MeModel";
import dateUtil from "util/dateUtil";
import alertUtil from "util/alertUtil";

const TicketModel = types
  .model("TicketModel", {
    id: types.identifierNumber,
    performanceId: types.number,
    // 2021-10-21 Commented because dietary requirements functionality is temporarily disabled
    // dietaryRequirement: types.maybeNull(DietaryRequirementsModel),
    eventCode: types.string,
    pincode: types.maybe(types.string),
    inviterName: types.maybe(types.string),
    inviterId: types.maybe(types.number),
    inviteeRelationType: types.maybe(
      types.enumeration(["SPOUSE", "COLLEAGUE", "FRIEND", "FAMILY"])
    ),
    user: types.maybe(MeModel),
    type: types.enumeration(["GROUP", "INDIVIDUAL", "PARTNER"]),
    initialRoomId: types.maybe(types.number),
    createdAt: types.string,
    scannedAt: types.maybe(types.string),
    startedAt: types.maybe(types.string),
    endedAt: types.maybe(types.string),
    hasSubmittedHelpQuestions: types.maybe(types.boolean),
  })
  .views((self) => ({
    get InviteeRelationType() {
      switch (self.inviteeRelationType) {
        case "COLLEAGUE":
          return "Collega";
        case "FAMILY":
          return "Familielid";
        case "FRIEND":
          return "Vriend";
        case "SPOUSE":
          return "Echtgenoot/echtgenote";
        default:
          return "Niet aangegeven";
      }
    },
  }))
  .views((self) => ({
    // 2021-10-21 Commented because dietary requirements functionality is temporarily disabled
    // get HasDietaryRequirements() {
    //   return (
    //     self.dietaryRequirement &&
    //     Object.keys(self.dietaryRequirement).some(
    //       (requirement) => self.dietaryRequirement[requirement]
    //     )
    //   );
    // },

    get IsScanned() {
      return self.scannedAt !== undefined;
    },

    get TableData() {
      const data = {
        id: self.id,
        user: self.user?.fullName,
        date: dateUtil.UtcStringToFormattedString(self.createdAt),
        initialRoomId: self.initialRoomId,
        // 2021-10-21 Commented because dietary requirements functionality is temporarily disabled
        // dietaryRequirement: self.dietaryRequirement,
        scannedAt: self.scannedAt,
        object: self,
      };
      switch (self.type) {
        case "INDIVIDUAL": {
          return { ...data };
        }
        case "PARTNER": {
          return {
            ...data,
            partnerType: self.InviteeRelationType,
            inviter: self.inviterName,
            inviterId: self.inviterId,
          };
        }
        case "GROUP": {
          return {
            ...data,
            user: self.user?.fullName,
          };
        }
      }
      return {
        type: self.type,
      };
    },

    get isGroup() {
      return self.type === "GROUP";
    },

    get isIndividual() {
      return self.type === "INDIVIDUAL";
    },

    get isPartner() {
      return self.type === "PARTNER";
    },
  }))
  .actions((self) => ({
    getTableData: flow(function* () {
      const performance = yield PerformanceService.single(self.performanceId);
      const location = yield LocationService.single(performance.locationId);
      const event = RootStore.EventStore.events.find((event: EventInstance) => {
        return event.id === location.eventId;
      }).storedValue;

      const date = moment(performance.date).format("DD-MM-YYYY");

      return {
        eventName: event.name,
        locationName: location.name,
        datetime: `${date} ${performance.from} - ${performance.to}`,
        isScanned: self.IsScanned,
      };
    }),
    patch: (ticket: TicketSnapshot) => {
      applySnapshot(self, ticket);
    },
    scan: flow(function* () {
      const ticket = yield TicketService.scan(self.id);

      if (ticket) {
        applySnapshot(self, ticket);
        alertUtil.success("Ticket gescand");
      }
    }),
  }));

export interface TicketInstance extends Instance<typeof TicketModel> {}
export interface TicketSnapshot extends SnapshotIn<typeof TicketModel> {}

export default TicketModel;
