import React, { useEffect } from "react";
import {
  LoginPage,
  ForgotPassword,
  ResetPassword,
  ActivateEmail,
} from "pages/Login";
import { Layout } from "shared";
import { observer } from "mobx-react-lite";

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import { useStores } from "stores";

import "./App.scss";
import {
  LocationsOverviewPage,
  LocationDetailPage,
  PerformanceDetailPage,
} from "pages/Locations";
import { RoomsOverviewPage, RoomDetailPage } from "pages/Rooms";
import { UsersOverviewPage, UserDetailPage } from "pages/Users";
import { EventDetailPage } from "pages/Events";
import { PersonasOverviewPage, PersonaDetailPage } from "pages/Personas";
import { EmployeesOverviewPage, EmployeeDetailPage } from "pages/Employees";
import { FaqOverviewPage, FaqDetailPage } from "pages/Faq";
import FragmentOverviewPage from "pages/Fragments/FragmentOverviewPage";
import FragmentDetailPage from "pages/Fragments/FragmentDetailPage";
import PostOverviewPage from "pages/News/PostOverviewPage";
import PostDetailPage from "pages/News/PostDetailPage";
import StatementsOverviewPage from "pages/Statements/StatementsOverviewPage";
import StatementDetailPage from "pages/Statements/StatementDetailPage";
import HelpQuestionOverviewPage from "pages/HelpQuestions/HelpQuestionOverviewPage";
import HelpQuestionDetailPage from "pages/HelpQuestions/HelpQuestionDetailPage";
import HelpQuestionAnswerOverviewPage from "pages/HelpQuestionAnswers/HelpQuestionAnswerOverviewPage";
import { PerformancesOverviewPage } from "pages/Performances";

const App = () => {
  const RootStore = useStores();

  useEffect(() => {
    async function loadData() {
      RootStore.load();
    }

    if (RootStore.isAuthenticated) {
      loadData();
    }
  }, [RootStore, RootStore.isAuthenticated]);

  const DefaultAdminStartRoutes = RootStore.User &&
    RootStore.User.IsAdmin && {
      locations: (
        <React.Fragment key="locations">
          <Route path="/locaties" exact component={LocationsOverviewPage} />
          <Route path="/locaties/:id" exact component={LocationDetailPage} />
        </React.Fragment>
      ),
    };

  const DefaultStartRoutes = {
    performances: (
      <React.Fragment key="performances">
        <Route
          path="/voorstellingen"
          exact
          component={PerformancesOverviewPage}
        />
        <Route
          path="/voorstellingen/:id"
          exact
          component={PerformanceDetailPage}
        />
      </React.Fragment>
    ),
  };

  const DefaultEndRoutes = RootStore.User && {
    users: (
      <React.Fragment key="users">
        <Route path="/gebruikers" exact component={UsersOverviewPage} />
        <Route path="/gebruikers/:id" exact component={UserDetailPage} />
      </React.Fragment>
    ),
  };

  const DefaultAdminEndRoutes = RootStore.User &&
    RootStore.User.IsAdmin && {
      posts: (
        <React.Fragment key="posts">
          <Route path="/berichten" exact component={PostOverviewPage} />
          <Route path="/berichten/:id" exact component={PostDetailPage} />
        </React.Fragment>
      ),
      faq: (
        <React.Fragment key="faq">
          <Route path="/faq" exact component={FaqOverviewPage} />
          <Route path="/faq/:id" exact component={FaqDetailPage} />
        </React.Fragment>
      ),
      statements: (
        <React.Fragment key="statements">
          <Route path="/stellingen" exact component={StatementsOverviewPage} />
          <Route path="/stellingen/:id" exact component={StatementDetailPage} />
        </React.Fragment>
      ),
      helpQuestions: (
        <React.Fragment key="helpQuestions">
          <Route
            path="/hulpvragen"
            exact
            component={HelpQuestionOverviewPage}
          />
          <Route
            path="/hulpvragen/:id"
            exact
            component={HelpQuestionDetailPage}
          />
        </React.Fragment>
      ),
    };

  const DefaultWijtechniekEndRoutes = {
    helpQuestionAnswers: (
      <React.Fragment key="helpQuestionAnswers">
        <Route
          path="/hulpvraag-antwoorden"
          exact
          component={HelpQuestionAnswerOverviewPage}
        />
        <Route
          path="/hulpvraag-antwoorden/:id"
          exact
          component={HelpQuestionDetailPage}
        />
      </React.Fragment>
    ),
  };

  const TheaterRoutes = RootStore.User &&
    RootStore.User.IsAdmin &&
    RootStore.currentEvent?.isTheater && {
      rooms: (
        <React.Fragment key="rooms">
          <Route path="/kamers" exact component={RoomsOverviewPage} />
          <Route path="/kamers/:id" exact component={RoomDetailPage} />
        </React.Fragment>
      ),
      personas: (
        <React.Fragment key="personas">
          <Route path="/personages" exact component={PersonasOverviewPage} />
          <Route path="/personages/:id" exact component={PersonaDetailPage} />
        </React.Fragment>
      ),
      employees: (
        <React.Fragment key="employees">
          <Route path="/medewerkers" exact component={EmployeesOverviewPage} />
          <Route path="/medewerkers/:id" exact component={EmployeeDetailPage} />
        </React.Fragment>
      ),
      fragments: (
        <React.Fragment key="fragments">
          <Route path="/fragmenten" exact component={FragmentOverviewPage} />
          <Route path="/fragmenten/:id" exact component={FragmentDetailPage} />
        </React.Fragment>
      ),
    };

  const Routes = {
    ...DefaultAdminStartRoutes,
    ...DefaultStartRoutes,
    ...TheaterRoutes,
    ...DefaultEndRoutes,
    ...DefaultAdminEndRoutes,
    ...DefaultWijtechniekEndRoutes,
  };

  let routes = (
    <Switch>
      {RootStore.isAuthenticated && RootStore.hasLoaded ? (
        <>
          {Object.keys(Routes).map((key) => Routes[key])}

          <Route
            key="changePassword"
            path="/wachtwoord-wijzigen"
            exact
            component={ResetPassword}
          />

          {RootStore.User.IsAdmin && (
            <>
              <Route key="addEvent" path="/evenement-toevoegen" exact>
                <EventDetailPage edit={false}></EventDetailPage>
              </Route>

              <Route key="editEvent" path="/evenement-wijzigen" exact>
                <EventDetailPage edit={true}></EventDetailPage>
              </Route>
            </>
          )}

          <Route key="login" path="/login" exact>
            <Redirect to={"/gebruikers"} />
          </Route>

          <Route key="default" path="/" exact>
            <Redirect to={"/gebruikers"} />
          </Route>
        </>
      ) : (
        <Switch>
          <Route key="login" path="/login" exact component={LoginPage} />

          <Route
            key="forgotPassword"
            path="/wachtwoord-vergeten"
            exact
            component={ForgotPassword}
          />

          <Route
            key="activateEmail"
            path="/email-activeren/:token"
            exact
            component={ActivateEmail}
          />

          <Route
            key="activateNewEmail"
            path="/nieuwe-email-activeren/:token"
            exact
            component={({ ...props }) => <ActivateEmail isUpdate {...props} />}
          />

          <Route
            key="resetPassword"
            path="/wachtwoord-resetten/:token"
            exact
            component={ResetPassword}
          />

          <Redirect to="/login" />
        </Switch>
      )}
    </Switch>
  );

  return (
    <Router>
      {RootStore.isAuthenticated !== undefined &&
      RootStore.hasLoaded !== undefined ? (
        <Layout routes={Routes}>{routes}</Layout>
      ) : null}
    </Router>
  );
};

export default observer(App);
