import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import Loader from "components/Loader";
import { EventInstance } from "stores/EventStore/EventModel";
import {
  TextInput,
  NumberInput,
  FileInput,
  RadioButtonGroup,
} from "./FormElements";

const EventForm = ({
  onSubmitCallback,
  event,
}: {
  onSubmitCallback: (
    eventData: Partial<EventInstance>,
    files: any
  ) => Promise<void>;
  event?: EventInstance;
}) => {
  const onSubmit = async (formEvent) => {
    setLoading(true);

    formEvent.preventDefault();

    const eventData: Partial<EventInstance> = {
      name: formEvent.target[2].value,
      link: formEvent.target[3].value,
      maximumRoomCapacity: formEvent.target[4].value || 0,
      type: selectedType,
    };

    if (event) {
      eventData.id = event.id;
    }

    const files = {
      // introFile,
      // practicalFile,
      testFile,
    };

    await onSubmitCallback(eventData, files);

    setLoading(false);
  };

  const [loading, setLoading] = useState(false);
  // const [introFile, setIntroFile] = useState<any>();
  const [testFile, setTestFile] = useState<any>();
  // const [practicalFile, setPracticalFile] = useState<any>();
  const [selectedType, setSelectedType] = useState<string>(
    event?.type ? event.type : "Theater"
  );

  const onFileChange = (event) => {
    switch (event.target.id) {
      // case "introFile":
      //   setIntroFile(event.target.files[0]);
      //   break;
      // case "practicalFile":
      //   setPracticalFile(event.target.files[0]);
      //   break;
      case "testFile":
        setTestFile(event.target.files[0]);
        break;
    }
  };

  const onTypeChange = (checked: boolean, type: string) => {
    setSelectedType(type);
  };

  return (
    <Form onSubmit={onSubmit}>
      <RadioButtonGroup
        label="Type"
        id="type"
        defaultValue={selectedType}
        onChange={onTypeChange}
        options={[
          {
            id: "Theater",
            label: "Theater",
          },
          {
            id: "External",
            label: "Overig",
          },
        ]}
      ></RadioButtonGroup>

      <TextInput
        label="Naam"
        id="name"
        placeholder="Naam"
        required={true}
        defaultValue={event?.name}
      ></TextInput>

      <TextInput
        label="Link"
        id="link"
        placeholder="Link (meer informatie)"
        required={false}
        defaultValue={event?.link}
      ></TextInput>

      {selectedType === "Theater" && (
        <>
          <NumberInput
            label="Maximum aantal bezoekers per kamer"
            id="maximumRoomCapacity"
            placeholder="Aantal"
            required={true}
            defaultValue={
              event?.maximumRoomCapacity ? event?.maximumRoomCapacity : 10
            }
          ></NumberInput>

          <FileInput
            label="Test fragment"
            id="testFile"
            type="audio"
            defaultValue={event?.testFragment}
            stateFile={testFile}
            onFileChange={onFileChange}
            required={false}
          ></FileInput>

          {/* <FileInput
            label="Intro fragment"
            id="introFile"
            type="audio"
            defaultValue={event?.introFragment}
            stateFile={introFile}
            onFileChange={onFileChange}
            required={false}
          ></FileInput> */}

          {/* <FileInput
            label="Praktisch fragment"
            id="practicalFile"
            type="audio"
            defaultValue={event?.practicalFragment}
            stateFile={practicalFile}
            onFileChange={onFileChange}
            required={false}
          ></FileInput> */}
        </>
      )}

      <Button type="submit" className="btn btn-primary mr-2 position-relative">
        <Loader position="right" display={loading}></Loader>
        {event ? "Opslaan" : "Toevoegen"}
      </Button>
    </Form>
  );
};

export default EventForm;
