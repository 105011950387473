import ApiService from "./ApiService";
import { RootStore } from "stores";

class AuthService {
  basename: string = "auth";

  public login = async (email: string, password: string): Promise<boolean> => {
    const response = await ApiService.post(`${this.basename}/login`, {
      email,
      password,
    });

    if (response.data) {
      var authResponse = response.data;

      ApiService.setAccessToken(
        authResponse.access_token,
        authResponse.expires_in
      );
      return true;
    }

    return false;
  };

  public logout = async () => {
    RootStore.reset();
    ApiService.clearLocalStorage();
  };

  public forgot = async (email: string) => {
    const response = await ApiService.post(`${this.basename}/forgot`, {
      email,
    });

    return response.status === 200;
  };

  public changePassword = async (password: string, token?: string) => {
    const response = await ApiService.post(`${this.basename}/reset`, {
      password,
      resetToken: token,
    });

    return response.status === 200;
  };

  public activateEmail = async (token: string, isUpdate: boolean = false) => {
    const endpoint = isUpdate
      ? "users/validateEmail"
      : `${this.basename}/activate`;
    const response = await ApiService.get(`/${endpoint}/${token}`);
    return response.status === 200;
  };
}

export default new AuthService();
