import React, { useEffect, useCallback } from "react";
import { observer } from "mobx-react-lite";
import { useHistory } from "react-router";

const DetailPage = <T extends unknown>({
  store,
  current,
  title,
  form,
  match,
  formColSize = 6,
}: {
  store?: any;
  current: T;
  title: string;
  form: JSX.Element;
  match: { params: { id: number } };
  formColSize?: number;
}) => {
  const history = useHistory();

  const load = useCallback(async () => {
    const result = await store.setCurrent(Number(match.params.id));
    if (!result) return history.push("/");
  }, [match.params.id, history, store]);

  useEffect(() => {
    if (store) {
      load();

      return () => {
        store.unsetCurrent();
      };
    }
  }, [load, store]);

  return current ? (
    <div>
      <div className="page-header">
        <h3 className="page-title">{title}</h3>
      </div>
      <div className="row">
        <div className={`col-md-${formColSize} grid-margin stretch-card`}>
          <div className="card">
            <div className="card-body">{form}</div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default observer(DetailPage);
