import React from "react";
import { Modal } from "react-bootstrap";

const CustomModal = ({
  visible,
  onHide,
  children,
  modalTitle,
  size = undefined,
}: {
  visible: boolean;
  onHide: () => void;
  children: any;
  modalTitle: string;
  size?: "sm" | "lg" | "xl" | undefined;
}) => {
  return (
    <>
      <Modal size={size} show={visible} onHide={() => onHide()}>
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>

        <Modal.Body>{children}</Modal.Body>
      </Modal>
    </>
  );
};

export default CustomModal;
