import { types, Instance, SnapshotIn } from "mobx-state-tree";

const LocationRoomModel = types.model({
  roomId: types.number,
});

export interface LocationRoomInstance
  extends Instance<typeof LocationRoomModel> {}
export interface LocationRoomSnapshot
  extends SnapshotIn<typeof LocationRoomModel> {}
export default LocationRoomModel;
