import React from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import { observer } from "mobx-react-lite";
import Select from "react-select-v2";
import { RequestFiltersInstance } from "stores/Models/RequestFilters";

const Pagination = ({
  filters,
  onPaginationChange,
}: {
  filters: RequestFiltersInstance;
  onPaginationChange: () => void;
}) => {
  const limits = [20, 50];

  const page = filters.page ? filters.page : 1;

  const onLimitChanged = (limit) => {
    filters.setLimit(limit);
    onPaginationChange();
  };

  return (
    <div className="row mt-3">
      <div className="col-md-4 col-xs-4 col-sm-4 col-lg-4 d-inline-flex justify-content-start">
        <Select
          onChange={(selectedLimit) => {
            onLimitChanged(selectedLimit.value);
          }}
          options={limits.map((option) => {
            return {
              value: option,
              label: option,
            };
          })}
          className="w-100"
          defaultValue={{ value: 20, label: 20 }}
          menuPlacement="top"
          isSearchable={false}
        />
      </div>

      {filters.totalPages && filters.totalPages > 1 ? (
        <div className="col-md-8 col-xs-8 col-sm-8 col-lg-8 w-100 d-inline-flex justify-content-end">
          <ButtonGroup className="pagination w-100">
            {filters.page === filters.totalPages && filters.page >= 3 ? (
              <Button
                variant="light"
                onClick={() => {
                  filters.setPage(page - 2);
                  onPaginationChange();
                }}
              >
                {filters.page - 2}
              </Button>
            ) : (
              <></>
            )}

            {filters.page > 1 ? (
              <Button
                variant="light"
                onClick={() => {
                  filters.setPage(page - 1);
                  onPaginationChange();
                }}
              >
                {filters.page - 1}
              </Button>
            ) : (
              <></>
            )}

            <Button>{filters.page}</Button>

            {filters.totalPages > filters.page ? (
              <Button
                variant="light"
                onClick={() => {
                  filters.setPage(page + 1);
                  onPaginationChange();
                }}
              >
                {filters.page + 1}
              </Button>
            ) : (
              <></>
            )}

            {filters.page === 1 && filters.totalPages >= 3 ? (
              <Button
                variant="light"
                onClick={() => {
                  filters.setPage(page + 2);
                  onPaginationChange();
                }}
              >
                {filters.page + 2}
              </Button>
            ) : (
              <></>
            )}
          </ButtonGroup>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default observer(Pagination);
