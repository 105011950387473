import React, { useState } from "react";
import { RootStore } from "stores";
import { PerformanceForm } from "components/forms";
import { PerformanceSnapshot } from "stores/PerformanceStore/PerformanceModel";
import DetailPage from "pages/Base/DetailPage";
import { observer } from "mobx-react-lite";
import { PerformanceAttendanceOverview } from "components/Locations";
import { ConfirmModal } from "components";
import { useHistory } from "react-router";

const PerformanceDetailPage = ({ match }: { match: { params: any } }) => {
  const onEditSubmit = async (
    performanceData: Partial<PerformanceSnapshot>
  ) => {
    await RootStore.PerformanceStore.current?.update(performanceData);
  };

  const history = useHistory();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const onRemoveSubmit = async () => {
    await RootStore.PerformanceStore.delete();
    history.push("/voorstellingen");
  };

  return (
    <>
      <DetailPage
        current={RootStore.PerformanceStore.current}
        store={RootStore.PerformanceStore}
        title="Voorstelling"
        form={
          <PerformanceForm
            onSubmitCallback={onEditSubmit}
            onRemoveCallback={() => {
              setShowDeleteModal(true);
            }}
            performance={RootStore.PerformanceStore.current}
          ></PerformanceForm>
        }
        formColSize={12}
        match={match}
      ></DetailPage>

      <ConfirmModal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        onConfirm={onRemoveSubmit}
        modalText="Weet je zeker dat je deze voorstelling wilt annuleren?"
        modalTitle={"Voorstelling annuleren"}
      ></ConfirmModal>

      {RootStore.PerformanceStore.current && <PerformanceAttendanceOverview />}
    </>
  );
};

export default observer(PerformanceDetailPage);
