import { types, Instance, SnapshotIn } from "mobx-state-tree";
import PerformanceModel, {
  PerformanceSnapshot,
} from "stores/PerformanceStore/PerformanceModel";

const AttendeeStoreModel = types
  .model("AttendeeStoreModel", {
    isOpen: false,
    performance: types.maybe(types.reference(PerformanceModel)),
  })
  .actions((self) => ({
    setOpen(isOpen: boolean) {
      self.isOpen = isOpen;
    },
  }))
  .actions((self) => ({
    openWithPerformance(performance: PerformanceSnapshot) {
      self.setOpen(true);
    },
  }));

export interface AttendeeStoreSnapshot
  extends SnapshotIn<typeof AttendeeStoreModel> {}
export interface AttendeeStoreInstance
  extends Instance<typeof AttendeeStoreModel> {}

export default AttendeeStoreModel;
