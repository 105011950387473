import CrudService from "./CrudService";
import PaginatedResponse from "stores/Models/PaginatedResponse";
import { RequestFiltersSnapshot } from "stores/Models/RequestFilters";
import { PostSnapshot } from "stores/PostStore/PostModel";
import { RoomSnapshot } from "stores/RoomStore/RoomModel";
import { EmployeeSnapshot } from "stores/EmployeeStore/EmployeeModel";

interface PostModel extends PostSnapshot {
  room?: RoomSnapshot;
  employee?: EmployeeSnapshot;
}

class PostService extends CrudService<PostModel> {
  listWithIncludes = async (
    filters: RequestFiltersSnapshot
  ): Promise<PaginatedResponse<PostModel>> => {
    const response = await this.list(filters);

    return response;
  };

  singleWithIncludes = async (id: number): Promise<PostSnapshot> => {
    var post = await this.single(id);

    return post;
  };

  createWithIncludes = async (
    postData: Partial<PostSnapshot>,
    files: any
  ): Promise<PostSnapshot> => {
    var post = await this.create(postData, files);

    return post;
  };

  updateWithIncludes = async (
    id: number,
    postData: Partial<PostSnapshot>,
    files: any
  ): Promise<PostSnapshot> => {
    var post = await this.update(id, postData, files);
    return post;
  };
}

export default new PostService("/posts");
