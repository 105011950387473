import React from "react";
import { RootStore } from "stores";
import { RoomForm } from "components/forms";
import { RoomSnapshot, RoomInstance } from "stores/RoomStore/RoomModel";
import DetailPage from "pages/Base/DetailPage";
import { observer } from "mobx-react-lite";

const RoomDetailPage = ({ match }: { match: { params } }) => {
  const onEditSubmit = async (roomData: Partial<RoomSnapshot>, files: any) => {
    await RootStore.RoomStore.current?.update(roomData, files);
  };

  return (
    <DetailPage<RoomInstance>
      current={RootStore.RoomStore.current}
      store={RootStore.RoomStore}
      title={RootStore.RoomStore.current?.name}
      form={
        <RoomForm
          onSubmitCallback={onEditSubmit}
          room={RootStore.RoomStore.current}
        ></RoomForm>
      }
      match={match}
    ></DetailPage>
  );
};

export default observer(RoomDetailPage);
