import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import Loader from "components/Loader";
import { TextInput, RadioButtonGroup, CheckInput } from "./FormElements";
import { observer } from "mobx-react-lite";
import {
  StatementInstance,
  STATEMENT_TYPES,
  StatementSnapshot,
} from "stores/StatementStore/StatementModel";

const StatementForm = ({
  onSubmitCallback,
  statement,
}: {
  onSubmitCallback: (
    statementData: Partial<StatementInstance>
  ) => Promise<void>;
  statement?: StatementInstance;
}) => {
  const onSubmit = async (formEvent) => {
    setLoading(true);

    formEvent.preventDefault();

    const statementData: StatementSnapshot = {
      active: formEvent.target[1].checked,
      featured: formEvent.target[2].checked,
    };

    if (!statement) {
      statementData.text = formEvent.target[0].value;
      statementData.type = type;

      if (type === "MULTIPLECHOICE") {
        statementData.questions =
          formEvent.target[5] && formEvent.target[5].value
            ? formEvent.target[5].value.split(";").map((answer) => {
                return {
                  answer,
                };
              })
            : [];
      }
    }

    if (statement) {
      statementData.yes = statement.yes;
      statementData.no = statement.no;
    }

    await onSubmitCallback(statementData);

    setLoading(false);
  };

  const [type, setType] = useState(
    statement ? statement.type : "MULTIPLECHOICE"
  );

  const [loading, setLoading] = useState(false);

  return (
    <Form onSubmit={onSubmit}>
      <TextInput
        label="Titel"
        id="text"
        placeholder="Titel"
        required={true}
        disabled={!!statement}
        defaultValue={statement?.text}
      ></TextInput>
      <CheckInput
        id="active"
        label="Actief"
        defaultValue={!!statement?.active}
      ></CheckInput>
      <CheckInput
        id="featured"
        label="Uitgelicht"
        defaultValue={!!statement?.featured}
      ></CheckInput>
      {statement ? (
        <div className="row">
          <div className="col-12">
            <p>Type: {STATEMENT_TYPES[statement.type]}</p>
          </div>
        </div>
      ) : (
        <RadioButtonGroup
          label="Categorie"
          id="category"
          options={Object.entries(STATEMENT_TYPES).map(([key, label]) => {
            return {
              id: key,
              label,
            };
          })}
          defaultValue={type}
          onChange={(_checked: boolean, name: string) => {
            setType(name);
          }}
        ></RadioButtonGroup>
      )}

      {!statement && type === "MULTIPLECHOICE" && (
        <TextInput
          label="Antwoorden"
          id="questions"
          placeholder="Acteur 1;Acteur 2;Acteur 3;Acteur 4"
          caption="Puntkomma gescheiden, bijv: 'Acteur 1;Acteur 2;Acteur 3;Acteur 4'"
          required={true}
          disabled={!!statement}
          defaultValue={statement?.questionsString}
        ></TextInput>
      )}

      {statement?.type === "MULTIPLECHOICE" && (
        <div className="row">
          <div className="col-12">
            {statement?.type === "MULTIPLECHOICE" &&
              statement.questions.map(({ answer, votes }) => (
                <p>
                  Antwoord {answer}: {votes} stemmen{" "}
                </p>
              ))}
          </div>
        </div>
      )}

      {statement?.type === "YESNO" && (
        <div className="row">
          <div className="col-12">
            <p>Antwoord "Ja": {statement.yes} stemmen </p>
            <p>Antwoord "Nee": {statement.no} stemmen </p>
          </div>
        </div>
      )}

      <Button type="submit" className="btn btn-primary mr-2 position-relative">
        <Loader position="right" display={loading}></Loader>
        {statement ? "Opslaan" : "Toevoegen"}
      </Button>
    </Form>
  );
};

export default observer(StatementForm);
