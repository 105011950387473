import React, { useState } from "react";
import { RootStore } from "stores";
import { observer } from "mobx-react-lite";
import { OverviewPage } from "pages/Base";
import { FaqSnapshot } from "stores/FaqStore/FaqModel";
import { FaqForm } from "components/forms";

const FaqOverviewPage = () => {
  const columns = [
    {
      dataField: "question",
      text: "Vraag",
    },
    {
      dataField: "formattedCategory",
      text: "Categorie",
    },
  ];

  const sortableFields = [
    {
      value: "category",
      label: "Categorie",
      defaultAscending: true,
    },
    {
      value: "question",
      label: "Vraag",
    },
    {
      value: "answer",
      label: "Antwoord",
    },
  ];

  const [showModal, setShowModal] = useState<boolean>();

  const onCreateSubmit = async (faq: Partial<FaqSnapshot>) => {
    await RootStore.FaqStore?.create(faq);
    setShowModal(false);
  };

  const form = <FaqForm onSubmitCallback={onCreateSubmit}></FaqForm>;

  return (
    <OverviewPage
      name="FAQ's"
      singular="FAQ"
      path="faq"
      onLoad={() => RootStore.FaqStore.load()}
      filters={RootStore.FaqStore.filters}
      columns={columns}
      items={RootStore.FaqStore.items}
      form={form}
      showModal={showModal}
      sort={true}
      search={true}
      sortableFields={sortableFields}
    ></OverviewPage>
  );
};

export default observer(FaqOverviewPage);
