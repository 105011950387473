import React, { useState } from "react";
import { RootStore } from "stores";
import { observer } from "mobx-react-lite";
import { OverviewPage } from "pages/Base";
import { Button } from "react-bootstrap";
import { CheckInput } from "components/forms/FormElements";

const UsersOverviewPage = () => {
  const columns = [
    {
      dataField: "fullName",
      text: "Naam",
    },
    {
      dataField: "email",
      text: "E-mail",
    },
    {
      dataField: "Role",
      text: "Rol",
    },
    {
      dataField: "activatedAt",
      text: "Geactiveerd",
      formatter: function Formatter(_cell, row) {
        return _cell ? (
          "Ja"
        ) : (
          <Button
            onClick={() => {
              row.activate();
            }}
          >
            Activeren
          </Button>
        );
      },
    },
  ];

  const sortableFields = [
    {
      label: "Naam",
      value: "firstName",
      defaultAscending: true,
    },
    {
      label: "E-mailadres",
      value: "email",
    },
  ];

  const [includeArchive, setIncludeArchive] = useState<boolean>();

  const onCheckIncludeArchive = async (active: boolean) => {
    setIncludeArchive(active);
  };

  const customFilters = (
    <CheckInput
      id="includeArchive"
      label="Gearchiveerde gebruikers tonen"
      defaultValue={false}
      key="includeArchive"
      onChange={onCheckIncludeArchive}
    ></CheckInput>
  );

  return (
    <OverviewPage
      name="Gebruikers"
      singular="Gebruiker"
      path="gebruikers"
      onLoad={() => RootStore.UserStore.load(includeArchive)}
      sort={true}
      search={true}
      filters={RootStore.UserStore.filters}
      columns={columns}
      items={RootStore.UserStore.users}
      sortableFields={sortableFields}
      customFilters={customFilters}
    ></OverviewPage>
  );
};

export default observer(UsersOverviewPage);
