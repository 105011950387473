import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { observer } from "mobx-react-lite";
import alertUtil from "util/alertUtil";
import { useHistory } from "react-router";
import { AuthService } from "services";
import { Loader } from "components";

const ForgotPassword = () => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    setLoading(true);

    event.preventDefault();
    const valid = await AuthService.forgot(event.target[0].value);

    if (valid) {
      alertUtil.success(
        "Een email is verstuurd naar het opgegeven e-mailadres"
      );
      return history.push("/login");
    }

    setLoading(false);
  };

  return (
    <div>
      <div className="d-flex align-items-center auth px-0">
        <div className="row w-100 mx-0">
          <div className="col-xl-4 col-md-8 mx-auto">
            <div className="auth-form-light text-left py-5 px-4 px-sm-5">
              <div className="brand-logo">
                <img
                  src={require("../../assets/images/logo-full.png")}
                  alt="logo"
                />
              </div>
              <h4>TheaterMakers Dashboard</h4>
              <h6 className="font-weight-light">Wachtwoord vergeten</h6>
              <Form className="pt-3" onSubmit={handleSubmit}>
                <Form.Group className="d-flex search-field">
                  <Form.Control
                    type="email"
                    placeholder="Email"
                    size="lg"
                    className="h-auto"
                    required
                  />
                </Form.Group>
                <div className="mt-3">
                  <button className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn position-relative">
                    VERSTUREN
                    <Loader
                      display={loading}
                      position={"right"}
                      style={{ top: "6px", right: "15px" }}
                      size={40}
                    ></Loader>
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(ForgotPassword);
