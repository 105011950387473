import React, { useState } from "react";
import { RootStore } from "stores";
import { observer } from "mobx-react-lite";
import { OverviewPage } from "pages/Base";
import { PersonaSnapshot } from "stores/PersonaStore/PersonaModel";
import { PersonaForm } from "components/forms";

const PersonasOverviewPage = () => {
  const columns = [
    {
      dataField: "name",
      text: "Naam",
    },
  ];

  const [showModal, setShowModal] = useState<boolean>();

  const onCreateSubmit = async (persona: Partial<PersonaSnapshot>, files) => {
    await RootStore.PersonaStore?.create(persona, files);
    setShowModal(false);
  };

  const form = <PersonaForm onSubmitCallback={onCreateSubmit}></PersonaForm>;

  return (
    <>
      <OverviewPage
        name="Personages"
        singular="Personage"
        path="personages"
        onLoad={() => RootStore.PersonaStore.load()}
        filters={RootStore.PersonaStore.filters}
        columns={columns}
        items={RootStore.PersonaStore.personas}
        form={form}
        showModal={showModal}
      ></OverviewPage>
    </>
  );
};

export default observer(PersonasOverviewPage);
