import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import Loader from "components/Loader";
import { TextInput, CheckInput } from "./FormElements";
import { observer } from "mobx-react-lite";
import {
  HelpQuestionInstance,
  HelpQuestionSnapshot,
} from "stores/HelpQuestionStore/HelpQuestionModel";

const HelpQuestionForm = ({
  onSubmitCallback,
  onRemoveCallback,
  helpQuestion,
}: {
  onSubmitCallback: (
    helpQuestionData: Partial<HelpQuestionInstance>
  ) => Promise<void>;
  onRemoveCallback?: () => void;
  helpQuestion?: HelpQuestionInstance;
}) => {
  const onSubmit = async (formEvent) => {
    setLoading(true);

    formEvent.preventDefault();

    const helpQuestionData: HelpQuestionSnapshot = {
      question: formEvent.target[0].value,
      title: formEvent.target[1].value,
      default: formEvent.target[2].checked,
    };

    await onSubmitCallback(helpQuestionData);

    setLoading(false);
  };

  const [loading, setLoading] = useState(false);

  return (
    <Form onSubmit={onSubmit}>
      <TextInput
        label="Vraag"
        id="question"
        placeholder="Vraag"
        required={true}
        disabled={!!helpQuestion}
        defaultValue={helpQuestion?.question}
      ></TextInput>

      <TextInput
        label="Titel (wordt bovenin de vraag weergegeven)"
        id="title"
        placeholder="Titel"
        required={true}
        defaultValue={helpQuestion?.title}
      ></TextInput>

      <CheckInput
        id="default"
        label="Standaard"
        defaultValue={!!helpQuestion?.default}
      ></CheckInput>

      {(!helpQuestion || !helpQuestion.deletedAt) && (
        <>
          <Button
            type="submit"
            className="btn btn-primary mr-2 position-relative"
          >
            <Loader position="right" display={loading}></Loader>
            {performance ? "Opslaan" : "Toevoegen"}
          </Button>

          {performance && (
            <Button variant="danger" onClick={onRemoveCallback}>
              Archiveren
            </Button>
          )}
        </>
      )}
    </Form>
  );
};

export default observer(HelpQuestionForm);
