import React from "react";
import { Form } from "react-bootstrap";
import { observer } from "mobx-react-lite";

const RadioButtonGroup = ({
  label,
  id,
  options,
  onChange,
  defaultValue,
}: {
  label: string;
  id: string;
  options: { id: string; label: string }[];
  onChange: (checked: boolean, name: string) => void;
  defaultValue?: string;
}) => {
  return (
    <Form.Group>
      <label htmlFor={id}>{label}</label>
      {options.map((option) => (
        <Form.Check
          type="radio"
          id={option.id}
          label={option.label}
          checked={defaultValue === option.id}
          onChange={(e) => onChange(e.target.checked, option.id)}
          className="form-check-input"
        />
      ))}
    </Form.Group>
  );
};

export default observer(RadioButtonGroup);
