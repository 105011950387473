import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import Loader from "./Loader";

const ConfirmModal = ({
  show,
  onHide,
  onConfirm,
  modalText,
  modalTitle,
}: {
  show: boolean;
  onHide: () => void;
  onConfirm: () => void;
  modalText: string;
  modalTitle: string;
}) => {
  const [loading, setLoading] = useState(false);

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{modalTitle}</Modal.Title>
      </Modal.Header>

      <Modal.Body>{modalText}</Modal.Body>

      <Modal.Footer className="flex-wrap">
        <Button
          onClick={async () => {
            setLoading(true);
            await new Promise((resolve) => setTimeout(resolve, 800));
            await onConfirm();
            setLoading(false);
          }}
          className="position-relative"
        >
          <Loader position="left" display={loading} />
          Ok
        </Button>
        <Button onClick={() => onHide()}>Annuleren</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmModal;
