import { types, SnapshotIn, Instance, flow, destroy } from "mobx-state-tree";
import RoomModel, { RoomSnapshot } from "./RoomModel";
import { RoomService } from "services";
import alertUtil from "util/alertUtil";

const RoomStoreModel = types
  .model("RoomStoreModel", {
    rooms: types.array(RoomModel),
    current: types.maybeNull(types.reference(RoomModel)),
  })
  .actions((self) => ({
    load: flow(function* () {
      const response = yield RoomService.listWithIncludes();
      self.rooms = response.results;
    }),
    get: flow(function* (id: number) {
      const room = yield RoomService.singleWithIncludes(id);
      if (room) self.rooms.push(room);
      return room;
    }),
    reset: () => {
      destroy(self.rooms);
    },
    create: flow(function* (roomData: Partial<RoomSnapshot>, files: any) {
      const room = yield RoomService.createWithIncludes(roomData, files);

      if (room) {
        self.rooms.push(room);
        alertUtil.success("De kamer is aangemaakt");
      }
    }),
  }))
  .actions((self) => ({
    setCurrent: flow(function* (id: number) {
      if (!self.rooms.some((room) => room.id === id)) {
        const room = yield self.get(id);
        if (!room) return false;
      }

      self.current = id as any;
      return true;
    }),
    unsetCurrent: () => {
      self.current = null;
    },
  }));

export interface RoomStoreSnapshot extends SnapshotIn<typeof RoomStoreModel> {}
export interface RoomStoreInstance extends Instance<typeof RoomStoreModel> {}

export default RoomStoreModel;
