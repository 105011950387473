import React, { useState } from "react";
import { RootStore } from "stores";
import { observer } from "mobx-react-lite";
import { LocationForm } from "components/forms";
import { OverviewPage } from "pages/Base";
import { LocationSnapshot } from "stores/LocationStore/LocationModel";

const LocationsOverviewPage = () => {
  const columns = [
    {
      dataField: "name",
      text: "Naam",
    },
    {
      text: "Adres",
      formatter: function Formatter(cell, row) {
        return (
          <div>
            {row.street} {row.houseNumber}, {row.zipCode}, {row.city}
          </div>
        );
      },
    },
  ];

  const sortableFields = [
    {
      value: "name",
      label: "Naam",
      defaultAscending: true,
    },
  ];

  const [showModal, setShowModal] = useState<boolean>();

  const onCreateSubmit = async (location: Partial<LocationSnapshot>) => {
    await RootStore.LocationStore?.create(location);
    setShowModal(false);
  };

  const form = <LocationForm onSubmitCallback={onCreateSubmit}></LocationForm>;

  return (
    <>
      <OverviewPage
        name="Locaties"
        singular="Locatie"
        path="locaties"
        onLoad={() => RootStore.LocationStore.load()}
        filters={RootStore.LocationStore.filters}
        columns={columns}
        items={RootStore.LocationStore.locations}
        form={form}
        showModal={showModal}
        sortableFields={sortableFields}
      ></OverviewPage>
    </>
  );
};

export default observer(LocationsOverviewPage);
