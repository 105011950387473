import { types, SnapshotIn, Instance, flow, destroy } from "mobx-state-tree";
import StatementModel, { StatementSnapshot } from "./StatementModel";
import { StatementService } from "services";
import alertUtil from "util/alertUtil";

const StatementStoreModel = types
  .model("StatementStoreModel", {
    statements: types.array(StatementModel),
    current: types.maybeNull(types.reference(StatementModel)),
  })
  .actions((self) => ({
    load: flow(function* () {
      const response = yield StatementService.list();
      self.statements = response.results;
    }),
    get: flow(function* (id: number) {
      const statement = yield StatementService.single(id);
      if (statement) self.statements.push(statement);
      return statement;
    }),
    reset: () => {
      destroy(self.statements);
    },
    create: flow(function* (statementData: Partial<StatementSnapshot>) {
      const statement = yield StatementService.create(statementData);

      if (statement) {
        self.statements.push(statement);
        alertUtil.success("De stelling is aangemaakt");
      }
    }),
  }))
  .actions((self) => ({
    setCurrent: flow(function* (id: number) {
      if (!self.statements.some((statement) => statement.id === id)) {
        const statement = yield self.get(id);
        if (!statement) return false;
      }

      self.current = id as any;
      return true;
    }),
    unsetCurrent: () => {
      self.current = null;
    },
  }));

export interface StatementStoreSnapshot
  extends SnapshotIn<typeof StatementStoreModel> {}
export interface StatementStoreInstance
  extends Instance<typeof StatementStoreModel> {}

export default StatementStoreModel;
