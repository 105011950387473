import React, { useState, useEffect, useCallback } from "react";
import { Form, Button, FormGroup } from "react-bootstrap";
import Loader from "components/Loader";
import { PostInstance } from "stores/PostStore/PostModel";
import {
  TextInput,
  FileInput,
  RadioButtonGroup,
  SearchSelect,
  CheckInput,
} from "./FormElements";
import { RootStore } from "stores";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import ApiService from "services/ApiService";
import Select from "react-select-v2";

const PostForm = ({
  onSubmitCallback,
  onRemoveCallback,
  post,
}: {
  onSubmitCallback: (
    postData: Partial<PostInstance>,
    files: any
  ) => Promise<void>;
  onRemoveCallback?: () => void;
  post?: PostInstance;
}) => {
  const [selectedStatement, setSelectedStatement] = useState<any>();
  const [statementOptions, setStatementOptions] = useState<any>(
    RootStore.StatementStore.statements.map((statement) => ({
      value: statement.id,
      label: statement.text,
    }))
  );

  const onSubmit = async (formEvent) => {
    setLoading(true);

    formEvent.preventDefault();

    let postData: Partial<PostInstance> = {
      type: selectedType,
      phase: selectedPhase,
      active: formEvent.target[5].checked,
      featured: formEvent.target[6].checked,
      title: formEvent.target[7].value,
    };

    if (selectedType === "NEWS") {
      postData = {
        ...postData,
        subtitle: formEvent.target[10].value,
        introduction: formEvent.target[11].value,
        firstBody: formEvent.target[12].value,
        imageCaption: formEvent.target[14].value,
        secondBody: formEvent.target[15].value,
        videoCaption: formEvent.target[16].value,
        secondTitle: formEvent.target[17].value,
        thirdBody: formEvent.target[18].value,
      };
    }

    if (customThumbnail) {
      postData.thumbnail = customThumbnail;
    }

    if (selectedStatement && selectedStatement.value) {
      postData.statementId = selectedStatement.value;
    }

    if (video && video.id) {
      postData.video = video.id;
    }

    if (id > 0) {
      if (selectedLink === "EMPLOYEE") {
        postData.employeeId = post ? post.employeeId : id;
      } else if (selectedLink === "ROOM") {
        postData.roomId = post ? post.roomId : id;
      }
    }

    if (post) {
      postData.id = post.id;
    }

    const files = { thumbnailFile, headerImageFile, bodyImageFile };

    await onSubmitCallback(postData, files);

    setLoading(false);
  };

  const loadStatement = useCallback(async () => {
    if (
      !RootStore.StatementStore.statements ||
      RootStore.StatementStore.statements.length <= 0
    ) {
      await RootStore.StatementStore.load();
    }

    setStatementOptions(
      RootStore.StatementStore.statements.map((statement) => ({
        value: statement.id,
        label: statement.text,
      }))
    );

    if (post && post.statementId) {
      const statement = RootStore.StatementStore.statements
        .slice()
        .find((statement) => statement.id === post.statementId);

      if (statement)
        setSelectedStatement({ value: statement.id, label: statement.text });
    }
  }, [post]);

  useEffect(() => {
    if (post && post.video) {
      onVideoChanged(post.video, false);
    }
  }, [post]);

  useEffect(() => {
    loadStatement();
  }, [loadStatement]);

  const [loading, setLoading] = useState(false);
  const [thumbnailFile, setThumbnailFile] = useState<any>();
  const [customThumbnail, setCustomThumbnail] = useState<any>();
  const [headerImageFile, setHeaderImageFile] = useState<any>();
  const [bodyImageFile, setBodyImageFile] = useState<any>();

  const [video, setVideo] = useState<{
    title: string;
    url: string;
    id: string;
    thumbnail: string;
  }>();
  const [selectedType, setSelectedType] = useState<"NEWS" | "VIDEO">(
    post?.type ? (post.type as "NEWS" | "VIDEO") : "NEWS"
  );

  const [selectedLink, setSelectedLink] = useState<
    "NONE" | "ROOM" | "EMPLOYEE"
  >(
    post && post.roomId ? "ROOM" : post && post.employeeId ? "EMPLOYEE" : "NONE"
  );

  const [selectedPhase, setSelectedPhase] = useState<
    "PRE_PERFORMANCE" | "POST_PERFORMANCE" | "ALWAYS"
  >(
    post
      ? (post.phase as "PRE_PERFORMANCE" | "POST_PERFORMANCE" | "ALWAYS")
      : "ALWAYS"
  );

  const [id, setId] = useState(
    post && post.roomId
      ? post.roomId
      : post && post.employeeId
      ? post.employeeId
      : -1
  );

  const onTypeChange = (_checked: boolean, type: string) => {
    setSelectedType(type as "NEWS" | "VIDEO");
  };

  const onLinkChange = (_checked: boolean, link: string) => {
    setSelectedLink(link as "NONE" | "ROOM" | "EMPLOYEE");
  };

  const onVideoChanged = async (
    value: string,
    setThumbnail: boolean = true
  ) => {
    setVideo(null);
    const response = await ApiService.get(`vimeo/${value}`);

    if (response.data && response.data.url.length > 0) {
      response.data.id = value;
      setVideo(response.data);

      if (setThumbnail) setCustomThumbnail(response.data.thumbnail);
    }
  };

  return (
    <Form onSubmit={onSubmit}>
      <div className="row">
        <div className="col-md-12">
          <RadioButtonGroup
            label="Type"
            id="type"
            defaultValue={selectedType}
            onChange={onTypeChange}
            options={[
              {
                id: "NEWS",
                label: "Bericht",
              },
              {
                id: "VIDEO",
                label: "Video",
              },
            ]}
          ></RadioButtonGroup>

          <RadioButtonGroup
            label="Type"
            id="type"
            defaultValue={selectedPhase}
            onChange={(_checked, name) =>
              setSelectedPhase(
                name as "PRE_PERFORMANCE" | "POST_PERFORMANCE" | "ALWAYS"
              )
            }
            options={[
              {
                id: "ALWAYS",
                label: "Altijd",
              },
              {
                id: "PRE_PERFORMANCE",
                label: "Voor voorstelling",
              },
              {
                id: "POST_PERFORMANCE",
                label: "Na voorstelling",
              },
            ]}
          ></RadioButtonGroup>

          <CheckInput
            id="active"
            label="Actief"
            defaultValue={post?.active}
          ></CheckInput>

          <CheckInput
            id="featured"
            label="Uitgelicht"
            defaultValue={post?.featured}
          ></CheckInput>

          <TextInput
            label="Titel"
            id="title"
            placeholder="Titel"
            required={true}
            defaultValue={post?.title}
          ></TextInput>

          <FileInput
            required={false}
            label="Thumbnail"
            id="thumbnailImage"
            type="image"
            defaultValue={customThumbnail ? customThumbnail : post?.thumbnail}
            stateFile={thumbnailFile}
            onFileChange={(event) => setThumbnailFile(event.target.files[0])}
          ></FileInput>

          {selectedType === "NEWS" && (
            <>
              <FileInput
                required={false}
                label="Kop afbeelding"
                id="headerImage"
                type="image"
                defaultValue={post?.headerImage}
                stateFile={headerImageFile}
                onFileChange={(event) =>
                  setHeaderImageFile(event.target.files[0])
                }
              ></FileInput>

              <TextInput
                label="Subtitel"
                id="subtitle"
                placeholder="Subtitel"
                required={false}
                defaultValue={post?.subtitle}
                rows={5}
              ></TextInput>

              <TextInput
                label="Introductie"
                id="introduction"
                placeholder="Introductie"
                required={false}
                defaultValue={post?.introduction}
                rows={5}
              ></TextInput>

              <TextInput
                label="Inhoud"
                id="firstBody"
                placeholder="Inhoud"
                required={false}
                defaultValue={post?.firstBody}
                rows={5}
              ></TextInput>

              <FileInput
                required={false}
                label="Inhoud afbeelding"
                id="bodyImage"
                type="image"
                defaultValue={post?.bodyImage}
                stateFile={bodyImageFile}
                onFileChange={(event) =>
                  setBodyImageFile(event.target.files[0])
                }
              ></FileInput>

              <TextInput
                label="Bijschrift afbeelding"
                id="imageCaption"
                placeholder="Bijschrift afbeelding"
                required={false}
                defaultValue={post?.imageCaption}
              ></TextInput>

              <TextInput
                label="Tweede inhoud"
                id="secondBody"
                placeholder="Tweede inhoud"
                required={false}
                defaultValue={post?.secondBody}
                rows={5}
              ></TextInput>

              <TextInput
                label="Video bijschrift"
                id="videoCaption"
                placeholder="Video bijschrift"
                required={false}
                defaultValue={post?.videoCaption}
              ></TextInput>

              <TextInput
                label="Tweede titel"
                id="secondTitle"
                placeholder="Tweede title"
                required={false}
                defaultValue={post?.secondTitle}
              ></TextInput>
              <TextInput
                label="Derde inhoud"
                id="thirdBody"
                placeholder="Derde inhoud"
                required={false}
                defaultValue={post?.thirdBody}
              ></TextInput>
            </>
          )}

          <TextInput
            label="Vimeo ID"
            id="video"
            placeholder="123456789"
            required={false}
            defaultValue={post?.video}
            onInputChanged={(value) =>
              onVideoChanged(value, selectedType === "VIDEO")
            }
          >
            {video && (
              <a href={video.url} target="_blank" rel="noopener noreferrer">
                {video.title}
              </a>
            )}
          </TextInput>

          {!post ? (
            <>
              <RadioButtonGroup
                label="Koppelen aan"
                id="link"
                defaultValue={selectedLink}
                onChange={onLinkChange}
                options={[
                  {
                    id: "NONE",
                    label: "Geen",
                  },
                  {
                    id: "ROOM",
                    label: "Kamer",
                  },
                  {
                    id: "EMPLOYEE",
                    label: "Medewerker",
                  },
                ]}
              ></RadioButtonGroup>

              {selectedLink !== "NONE" && (
                <>
                  {selectedLink === "EMPLOYEE" ? (
                    <SearchSelect
                      label="Medewerker"
                      id="employee"
                      key="employee"
                      onSearch={async (searchPhrase: string) => {
                        RootStore.EmployeeStore.filters.setSearchPhrase(
                          searchPhrase,
                          true
                        );

                        await RootStore.EmployeeStore.load();

                        return RootStore.EmployeeStore.employees.map(
                          (employee) => ({
                            value: employee.id,
                            label: employee.name,
                          })
                        );
                      }}
                      required={false}
                      onChange={(value) => setId(Number(value))}
                    />
                  ) : (
                    <SearchSelect
                      label="Kamer"
                      id="room"
                      key="room"
                      required={false}
                      customOptions={RootStore.RoomStore.rooms.map((room) => ({
                        value: room.id,
                        label: room.name,
                      }))}
                      onChange={(value) => setId(Number(value))}
                    />
                  )}
                </>
              )}
            </>
          ) : (
            <>
              {post.employeeName && (
                <Form.Group>
                  <label>Medewerker</label>
                  <p>
                    <Link to={`/medewerkers/${post.employeeId}`}>
                      {post.employeeName}
                    </Link>
                  </p>
                </Form.Group>
              )}

              {post.roomName && (
                <Form.Group>
                  <label>Kamer</label>
                  <p>
                    <Link to={`/kamers/${post.roomId}`}>{post.roomName}</Link>
                  </p>
                </Form.Group>
              )}
            </>
          )}

          {selectedType === "NEWS" && (
            <FormGroup>
              <label>Stelling</label>
              <Select
                options={statementOptions}
                defaultValue={null}
                value={selectedStatement}
                onChange={(value) => {
                  setSelectedStatement(value);
                }}
              ></Select>
            </FormGroup>
          )}

          <Button
            type="submit"
            className="btn btn-primary mr-2 position-relative"
          >
            <Loader position="right" display={loading}></Loader>
            {post ? "Opslaan" : "Toevoegen"}
          </Button>

          {post && (
            <Button variant="danger" onClick={onRemoveCallback}>
              Verwijderen
            </Button>
          )}
        </div>
      </div>
    </Form>
  );
};

export default observer(PostForm);
