import CrudService from "./CrudService";
import { HelpQuestionAnswerSnapshot } from "stores/HelpQuestionAnswerStore/HelpQuestionAnswerModel";
import PaginatedResponse from "stores/Models/PaginatedResponse";
import { RequestFiltersSnapshot } from "stores/Models/RequestFilters";
import { HelpQuestionSnapshot } from "stores/HelpQuestionStore/HelpQuestionModel";

interface HelpQuestionAnswerModel extends HelpQuestionAnswerSnapshot {
  helpQuestion?: HelpQuestionSnapshot;
}

class HelpQuestionAnswerService extends CrudService<
  HelpQuestionAnswerSnapshot
> {
  listWithIncludes = async (
    filters: RequestFiltersSnapshot,
    date: string,
    includeArchive: boolean
  ): Promise<PaginatedResponse<HelpQuestionAnswerSnapshot>> => {
    let response;

    let archive = includeArchive ? 1 : 0;

    if (date) {
      response = await this.list(null, { date, includeArchive: archive });
    } else {
      response = await this.list(filters, { includeArchive: archive });
    }

    response.results = response.results.map((helpQuestionAnswer) => {
      return this.transform(helpQuestionAnswer);
    });

    return response;
  };

  transform = (
    helpQuestionAnswer: HelpQuestionAnswerModel
  ): HelpQuestionAnswerSnapshot => {
    if (helpQuestionAnswer) {
      helpQuestionAnswer.helpQuestionTitle = helpQuestionAnswer.helpQuestion.question;
    }
    return helpQuestionAnswer;
  };
}

export default new HelpQuestionAnswerService("/helpquestionanswers");
