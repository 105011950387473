import { types, Instance, SnapshotIn } from "mobx-state-tree";

const MeModel = types
  .model("MeModel", {
    id: types.identifierNumber,
    email: types.string,
    firstName: types.string,
    lastName: types.string,
    fullName: types.string,
    image: types.maybe(types.string),
    role: types.string,
  })
  .views((self) => ({
    get IsAdmin() {
      return self.role === "ADMIN";
    },
  }));

export interface MeInstance extends Instance<typeof MeModel> {}
export interface MeSnapshot extends SnapshotIn<typeof MeModel> {}

export default MeModel;
