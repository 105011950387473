import { types, SnapshotIn, Instance, flow, destroy } from "mobx-state-tree";
import HelpQuestionModel, { HelpQuestionSnapshot } from "./HelpQuestionModel";
import alertUtil from "util/alertUtil";
import { HelpQuestionService } from "services";
import RequestFiltersModel from "stores/Models/RequestFilters";

const HelpQuestionStore = types
  .model("HelpQuestionStore", {
    helpQuestions: types.array(HelpQuestionModel),
    current: types.maybeNull(types.reference(HelpQuestionModel)),
    filters: RequestFiltersModel,
  })
  .actions((self) => ({
    load: flow(function* (includeArchive: boolean = false) {
      const response = yield HelpQuestionService.list(self.filters, {
        includeArchive: includeArchive ? 1 : 0,
      });
      self.helpQuestions = response.results;
      self.filters.update(response);
    }),
    get: flow(function* (id: number) {
      const helpQuestion = yield HelpQuestionService.single(id);
      if (helpQuestion) self.helpQuestions.push(helpQuestion);
      return helpQuestion;
    }),
    reset: () => {
      destroy(self.helpQuestions);
      self.filters = RequestFiltersModel.create();
    },
    create: flow(function* (helpQuestionData: Partial<HelpQuestionSnapshot>) {
      const helpQuestion = yield HelpQuestionService.create(helpQuestionData);

      if (helpQuestion) {
        self.helpQuestions.push(helpQuestion);
        alertUtil.success("De stelling is aangemaakt");
      }
    }),
    delete: flow(function* () {
      yield HelpQuestionService.delete(self.current.id);
      self.current = null;
    }),
  }))
  .actions((self) => ({
    setCurrent: flow(function* (id: number) {
      if (!self.helpQuestions.some((helpQuestion) => helpQuestion.id === id)) {
        const helpQuestion = yield self.get(id);
        if (!helpQuestion) return false;
      }

      self.current = id as any;
      return true;
    }),
    unsetCurrent: () => {
      self.current = null;
    },
  }));

export interface HelpQuestionStoreSnapshot
  extends SnapshotIn<typeof HelpQuestionStore> {}
export interface HelpQuestionStoreInstance
  extends Instance<typeof HelpQuestionStore> {}

export default HelpQuestionStore;
