import React, { useState } from "react";
import { RootStore } from "stores";
import { UserForm } from "components/forms";
import DetailPage from "pages/Base/DetailPage";
import { observer } from "mobx-react-lite";
import { ConfirmModal } from "components";
import { TicketOverview } from "components/Users";

const UserDetailPage = ({
  match,
  history,
}: {
  match: { params: any };
  history: any;
}) => {
  const user = RootStore.UserStore.current;
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  const onArchiveClick = async () => {
    const result = await user?.archive();

    if (result) {
      setDeleteModalVisible(false);
      return history.push("/gebruikers");
    }
  };

  return (
    <>
      <DetailPage
        current={user}
        store={RootStore.UserStore}
        title={user?.fullName}
        form={
          <UserForm
            user={user}
            onRemoveClick={() => {
              setDeleteModalVisible(true);
            }}
          ></UserForm>
        }
        match={match}
        formColSize={12}
      ></DetailPage>

      <div className="row mb-5">
        <div className="col-lg-12">{user && <TicketOverview />}</div>
      </div>

      <ConfirmModal
        show={deleteModalVisible}
        onHide={() => setDeleteModalVisible(false)}
        onConfirm={onArchiveClick}
        modalText="Weet je zeker dat je deze gebruiker wilt archiveren?"
        modalTitle={user?.fullName + " archiveren"}
      ></ConfirmModal>
    </>
  );
};

export default observer(UserDetailPage);
