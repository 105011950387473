import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { observer } from "mobx-react-lite";
import alertUtil from "util/alertUtil";
import { AuthService } from "services";
import { useHistory } from "react-router";
import { RootStore } from "stores";

const ResetPassword = ({ match }) => {
  const history = useHistory();
  const [password, setPassword] = useState("");
  const [success, setSuccess] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");

  const submittable = password !== confirmPassword || password === "";

  const handleSubmit = async (event) => {
    if (event.currentTarget.checkValidity()) {
      event.preventDefault();

      if (
        password.length > 0 &&
        confirmPassword.length > 0 &&
        password === confirmPassword
      ) {
        const success = await AuthService.changePassword(
          password,
          match.params.token
        );

        if (success) {
          alertUtil.success("Uw wachtwoord is gewijzigd");
          setSuccess(success);

          if (!RootStore.isAuthenticated && !match.params.token) {
            return history.push("/login");
          }
        }
      }
    }
  };

  return (
    <div>
      <div className="d-flex align-items-center auth px-0">
        <div className="row w-100 mx-0">
          <div className="col-xl-4 col-md-8">
            <div className="auth-form-light text-left py-5 px-4 px-sm-5">
              {match.params.token && (
                <div className="brand-logo">
                  <img
                    src={require("../../assets/images/logo-hvs.png")}
                    alt="logo"
                  />
                </div>
              )}

              {match.params.token && success ? (
                <>
                  Je wachtwoord is gewijzigd. Je kan dit tabblad nu sluiten en
                  opnieuw inloggen in de app!
                </>
              ) : (
                <>
                  <h6 className="font-weight-light">Wijzig je wachtwoord</h6>
                  <Form className="pt-3" onSubmit={handleSubmit}>
                    <Form.Group className="d-flex">
                      <Form.Control
                        type="password"
                        placeholder="Wachtwoord"
                        size="lg"
                        className="h-auto"
                        required
                        onChange={(event) => setPassword(event.target.value)}
                      />
                    </Form.Group>
                    <Form.Group className="d-flex mb-3">
                      <Form.Control
                        type="password"
                        placeholder="Bevestig je wachtwoord"
                        size="lg"
                        className="h-auto"
                        required
                        onChange={(event) =>
                          setConfirmPassword(event.target.value)
                        }
                      />
                    </Form.Group>

                    <p className="text-danger mt-0">
                      {password.length > 0 &&
                      confirmPassword.length > 0 &&
                      password !== confirmPassword
                        ? "De ingevulde wachtwoorden komen niet overeen"
                        : ""}
                    </p>

                    <div className="mt-3">
                      <button
                        disabled={submittable}
                        className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                      >
                        VERSTUREN
                      </button>
                    </div>
                  </Form>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(ResetPassword);
