import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import Loader from "components/Loader";
import { FaqInstance } from "stores/FaqStore/FaqModel";
import { TextInput, RadioButtonGroup } from "./FormElements";
import { observer } from "mobx-react-lite";

const FaqForm = ({
  onSubmitCallback,
  onRemoveCallback,
  faq,
}: {
  onSubmitCallback: (faqData: Partial<FaqInstance>) => Promise<void>;
  onRemoveCallback?: () => void;
  faq?: FaqInstance;
}) => {
  const onSubmit = async (formEvent) => {
    setLoading(true);

    formEvent.preventDefault();

    const faqData: Partial<FaqInstance> = {
      question: formEvent.target[0].value,
      answer: formEvent.target[1].value,
      category: category,
    };

    if (faq) {
      faqData.id = faq.id;
    }

    await onSubmitCallback(faqData);

    setLoading(false);
  };

  const [category, setCategory] = useState(faq ? faq.category : "GENERAL");
  const [loading, setLoading] = useState(false);

  return (
    <Form onSubmit={onSubmit}>
      <TextInput
        label="Vraag"
        id="question"
        placeholder="Vraag"
        required={true}
        rows={5}
        defaultValue={faq?.question}
      ></TextInput>

      <TextInput
        label="Antwoord"
        id="answer"
        placeholder="Antwoord"
        required={true}
        rows={5}
        defaultValue={faq?.answer}
      ></TextInput>

      <RadioButtonGroup
        label="Categorie"
        id="category"
        options={[
          {
            id: "GENERAL",
            label: "Algemeen",
          },
          {
            id: "TICKETS",
            label: "Tickets",
          },
          {
            id: "PERFORMANCES",
            label: "Bezoek Huis van Sarah",
          },
        ]}
        defaultValue={category}
        onChange={(_checked: boolean, name: string) => {
          setCategory(name);
        }}
      ></RadioButtonGroup>

      <Button type="submit" className="btn btn-primary mr-2 position-relative">
        <Loader position="right" display={loading}></Loader>
        {faq ? "Opslaan" : "Toevoegen"}
      </Button>

      {faq && (
        <Button variant="danger" onClick={onRemoveCallback}>
          Verwijderen
        </Button>
      )}
    </Form>
  );
};

export default observer(FaqForm);
