import React from "react";
import { RootStore } from "stores";
import { observer } from "mobx-react-lite";
import { EventForm } from "components/forms";
import { EventInstance } from "stores/EventStore/EventModel";

const EventDetailPage = ({ edit }: { edit: boolean; _type?: string }) => {
  const event = edit ? RootStore.currentEvent : null;

  const onEditSubmit = async (
    eventData: Partial<EventInstance>,
    files: any
  ) => {
    await event?.update(event.id, eventData, files);
  };

  const onCreateSubmit = async (event: Partial<EventInstance>, files: any) => {
    await RootStore.EventStore?.create(event, files);
  };

  return (
    <div>
      <div className="page-header">
        <h3 className="page-title">
          Evenement {event ? "wijzigen" : "toevoegen"}
        </h3>
      </div>
      <div className="row">
        <div className="col-md-6 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <EventForm
                onSubmitCallback={edit ? onEditSubmit : onCreateSubmit}
                event={event}
              ></EventForm>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(EventDetailPage);
