import alertUtil from "./alertUtil";
import { API_ERRORS } from "strings";

class errorUtil {
  private default = API_ERRORS["UNKNOWN"];

  public handleError(error: string = "UNKNOWN"): boolean {
    if (API_ERRORS[error]) {
      alertUtil.error(API_ERRORS[error]);
      return true;
    }

    alertUtil.error(this.default);
    return false;
  }
}

export default new errorUtil();
