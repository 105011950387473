import {
	types,
	Instance,
	flow,
	applySnapshot,
	SnapshotIn,
} from "mobx-state-tree";
import EventModel from "stores/EventStore/EventModel";
import { RoomService } from "services";
import alertUtil from "util/alertUtil";

const RoomModel = types
	.model("RoomModel", {
		id: types.identifierNumber,
		name: types.string,
		description: types.string,
		icon: types.maybe(types.string),
		image: types.maybe(types.string),
		video: types.maybe(types.string),
		color: types.maybe(types.string),
		event: types.reference(EventModel),
		eventId: types.number,
		employeeId: types.maybe(types.number),
		employeeName: types.maybe(types.string),
	})
	.actions((self) => ({
		update: flow(function* (
			roomData: Partial<SnapshotIn<typeof RoomModel>>,
			files: any
		) {
			const room = yield RoomService.updateWithIncludes(
				self.id,
				roomData,
				files
			);

			if (room) {
				room.employeeName = self.employeeName;
				applySnapshot(self, room);
				alertUtil.success("De kamer is bijgewerkt");
			}
		}),
	}));

export interface RoomInstance extends Instance<typeof RoomModel> {}
export interface RoomSnapshot extends SnapshotIn<typeof RoomModel> {}

export default RoomModel;
