import cogoToast from "cogo-toast";

class alertUtil {
  private audio = new Audio(require("assets/audio/alert.mp3"));

  private options = {
    position: "top-right",
  };

  public success = (message: string) => {
    cogoToast.success(message, this.options as any);
  };

  public error = (message: string) => {
    cogoToast.error(message, this.options as any);
  };

  public ping() {
    this.audio.play();
  }
}

export default new alertUtil();
