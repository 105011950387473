import React from "react";
import { RootStore } from "stores";
import { LocationForm } from "components/forms";
import { LocationSnapshot } from "stores/LocationStore/LocationModel";
import DetailPage from "pages/Base/DetailPage";
import { observer } from "mobx-react-lite";

const LocationDetailPage = ({ match }: { match: { params: any } }) => {
  const onEditSubmit = async (locationData: Partial<LocationSnapshot>) => {
    await RootStore.LocationStore.current?.update(locationData);
  };

  return (
    <>
      <DetailPage
        current={RootStore.LocationStore.current}
        store={RootStore.LocationStore}
        title={RootStore.LocationStore.current?.name}
        form={
          <LocationForm
            onSubmitCallback={onEditSubmit}
            location={RootStore.LocationStore.current}
          ></LocationForm>
        }
        formColSize={12}
        match={match}
      ></DetailPage>

      {/* <div className="row mb-5">
        <div className="col-lg-12">
          {RootStore.LocationStore.current && <PerformanceOverview />}
        </div>
      </div> */}
    </>
  );
};

export default observer(LocationDetailPage);
