import CrudService from "./CrudService";
import { PersonaSnapshot } from "stores/PersonaStore/PersonaModel";
import PaginatedResponse from "stores/Models/PaginatedResponse";

class PersonaService extends CrudService<PersonaSnapshot> {
  listWithIncludes = async (): Promise<PaginatedResponse<PersonaSnapshot>> => {
    const response = await this.list();

    response.results = response.results.map((persona) => {
      return {
        ...persona,
        event: persona.eventId,
      };
    });

    return response;
  };

  singleWithIncludes = async (id: number): Promise<PersonaSnapshot> => {
    const persona = await this.single(id);
    return this.transform(persona);
  };

  createWithIncludes = async (
    personaData: Partial<PersonaSnapshot>,
    files
  ): Promise<PersonaSnapshot> => {
    var persona = await this.create(personaData, files);

    return this.transform(persona);
  };

  updateWithIncludes = async (
    id: number,
    personaData: Partial<PersonaSnapshot>,
    files
  ): Promise<PersonaSnapshot> => {
    var persona = await this.update(id, personaData, files);
    return this.transform(persona);
  };

  transform = (persona: PersonaSnapshot): PersonaSnapshot => {
    if (persona) persona.event = persona.eventId;
    return persona;
  };
}

export default new PersonaService("/personas");
